import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import {
  USER_LOGIN_URL,
  USER_REGISTER_URL,
  VERIFY_USER_LOGIN_URL,
} from "../utils/api_services";
import { verifyToken } from "../store/actions/authActions";
import { useDispatch } from "react-redux";

import Loglogo from "../img/ROYAL LOTTARY.png";

import { Client, Account, ID } from "appwrite";
export default function LoginScreen() {
  const dispatch = useDispatch();

  const [userId, setUserId] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState("");

  const handleClickRedirect = () => {
    return navigate("/authorization");
  };

  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const [isSendOtp, setIsSendOtp] = useState(false);
  const [isOtp, setIsOtp] = useState(null);

  const [isLoadingSignpUp, setIsLoadingSignpUp] = useState(false);
  const [isData, setIsData] = useState(null);
  const [user, setUser] = useState({
    mobileno: "",
  });

  const [timer, setTimer] = useState(90); // Initial timer value in seconds
  const [isButtonClickable, setIsButtonClickable] = useState(true);
  const appwriteClient = new Client()
    .setEndpoint("https://cloud.appwrite.io/v1")
    .setProject("656078b0263825a1ecc4");

  useEffect(() => {
    let interval;

    if (timer > 0 && !isButtonClickable) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setIsButtonClickable(true);
    }

    return () => clearInterval(interval);
  }, [timer, isButtonClickable]);
  const onSignUpClick = async (event) => {
    event.preventDefault();

    try {
      setIsLoadingSignpUp(true);

      const response = await axios.post(USER_LOGIN_URL, user);
      const res = await response.data;
      const info = res.data;
      setIsSendOtp(true);
      setIsData(info);

      const account = new Account(appwriteClient);
      const sessionToken = await account.createPhoneSession(
        ID.unique(),
        "+91" + user.mobileno
      );

      const newUserId = sessionToken.userId;
      setUserId(newUserId);
      toast.success("Otp send successfully!");
    } catch (err) {
      toast.error(err.response?.data?.message ?? "Something Went Wrong!");
      return;
    } finally {
      setIsLoadingSignpUp(false);
      return;
    }
  };

  const verifyOtp = async (event) => {
    event.preventDefault();
    const account = new Account(appwriteClient);

    try {
      if (!isOtp || !userId) {
        toast.error("Please enter OTP and ensure a valid user ID.");
        return;
      }
      setIsLoadingSignpUp(true);
      await account.updatePhoneSession(userId, isOtp);
      const userdata = {
        user_id: isData.userId,
        token: isData.token,
      };
      const response = await axios.post(VERIFY_USER_LOGIN_URL, userdata);
      const res = await response.data;
      const info = res.data;
      localStorage.setItem("RoyalGmAccessToken", info);
      dispatch(verifyToken());
      return navigate("/home");
    } catch (err) {
      toast.error(err.response?.data?.message ?? "Something Went Wrong!");
      return;
    } finally {
      setIsLoadingSignpUp(false);
      return;
    }
  };
  const resendOtp = async () => {
    try {
      setTimer(90); // Reset the timer
      setIsButtonClickable(false); // Disable the button
      const account = new Account(appwriteClient);
      const sessionToken = await account.createPhoneSession(
        ID.unique(),
        "+91" + user.mobileno
      );

      const newUserId = sessionToken.userId;
      setUserId(newUserId);
      toast.success("OTP Sended Successfully");
    } catch (err) {
      toast.error("not send resend otp");
      return;
    }
  };
  return (
    <>
      <header className="header">
        <h3 style={{ fontfamily: "Dragon Hunter", fontWeight: "700" }}>
          ROYAL LOTTARY
        </h3>
      </header>

      <div className="login_container">
        <div className="login__container-wrapper">
          {isSendOtp ? (
            <>
              <div
                className="login__container-heading"
                style={{ margin: "1 0" }}
              >
                <h2>OTP Verification</h2>
              </div>
              <form onSubmit={verifyOtp} method="post">
                <div className="signup_container-form">
                  <div className="step1">
                    <div className="signup_input-field">
                      <input
                        type="text"
                        maxLength="50"
                        name=""
                        id="otpValue"
                        placeholder="Enter otp!"
                        required={true}
                        value={isOtp}
                        onChange={(e) => setIsOtp(e.target.value)}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: "15px",
                          margin: "10px 0",
                        }}
                      >
                        <p>Do not get the OTP ?</p>

                        {isButtonClickable ? (
                          <button
                            className="btn"
                            style={{ flexBasis: "content", fontSize: "12px" }}
                            onClick={resendOtp}
                          >
                            Resend OTP
                          </button>
                        ) : (
                          <span>
                            Resend OTP in {Math.floor(timer / 60)}:
                            {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                          </span>
                        )}
                      </div>
                    </div>

                    {isLoadingSignpUp ? (
                      <button
                        className="btn"
                        id="loginBtn"
                        type="button"
                        disabled=""
                      >
                        <span
                          className="spinner-grow"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        &nbsp;Verifing Please Wait ...
                      </button>
                    ) : (
                      <button className="btn" id="loginBtn" type="submit">
                        Verify OTP
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </>
          ) : (
            <>
              <div className="login__container-heading">
                <h2>SignIn to your Account</h2>
              </div>

              <form onSubmit={onSignUpClick} method="post">
                <div className="login_container-form">
                  <div className="step1">
                    <img src={Loglogo} alt="" style={{ width: 100 }} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <label
                        className="floting-label"
                        htmlFor=""
                        style={{ fontSize: 12 }}
                      >
                        Phone Number
                      </label>
                    </div>

                    <div
                      className="input-field "
                      style={{ marginBottom: "1rem" }}
                    >
                      <div className="num">
                        <div className="num-drpdwn dropdown">
                          <div>
                            <p>+91</p>
                          </div>
                        </div>

                        <input
                          type="text"
                          inputMode="numeric"
                          maxLength="10"
                          name=""
                          id="mobileInput"
                          placeholder="Enter your mobile no."
                          required={true}
                          value={user.mobileno}
                          onChange={(e) =>
                            setUser({ ...user, mobileno: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    {isLoadingSignpUp ? (
                      <button
                        className="btn"
                        id="loginBtn"
                        type="button"
                        disabled=""
                      >
                        <span
                          className="spinner-grow"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        &nbsp; Please Wait ...
                      </button>
                    ) : (
                      <button className="btn" id="loginBtn" type="submit">
                        Proceed
                      </button>
                    )}
                  </div>
                </div>
              </form>
              <div className="subheading">
                <p>
                  <strong>Don't have an account?</strong>
                </p>
                <span>|</span>
                <Link to="/signup">
                  <strong>
                    <span>Sign Up now</span>
                  </strong>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
