import React from "react";

export default function Progressbar(props) {
  const { completed } = props;

  const containerStyles = {
    height: 10,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 10,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: "#870000",
    borderRadius: "inherit",
    transition: "width 1s ease-in-out",
    textAlign: "right",
  };

  const labelStyles = {
    color: "#ffffff00",
  };

  return (
    <>
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${completed}%`}</span>
        </div>
      </div>
    </>
  );
}
