import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import support from "../img/support.svg";
import phone from "../img/phone.png";
import { IoIosArrowForward } from "react-icons/io";
import { IoChatbox, IoMail, IoPhoneLandscape } from "react-icons/io5";
import { BiChat, BiMailSend, BiPhoneCall } from "react-icons/bi";
export default function SignupScreen() {
  return (
    <>
      {/* <div className="nav">
        <div
          className="nav-left"
          style={{ zIndex: 100, marginLeft: 10, marginTop: 5 }}
        >
          <Link to="/home">
            <svg
              height="1.5rem"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs></defs>
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                style={{ fill: "#ffffff" }}
              ></path>
            </svg>
          </Link>
        </div>
      </div> */}

      <div className="support_container">
        <div className="customer-support">
          <div className="head-img">
            <img src={support} alt="support" />
          </div>
          <div className="list">
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "repeat(3)",
                gridTemplateColumns:'1fr 3fr 1fr',
                paddingLeft: '1rem',
              }}
            >
              <div className="">
                <BiPhoneCall style={{fontSize:'20px'}}/>
              </div>

              <div className="">
                <h3>Call Us</h3>
              </div>
              <div className="" style={{textAlign:'center'}}>
                <i>
                  <IoIosArrowForward />
                </i>
              </div>
            </div>
          </div>

          <div className="list">
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "repeat(3)",
                gridTemplateColumns:'1fr 3fr 1fr',
                paddingLeft: '1rem',
              }}
            >
              <div className="">
                <BiChat style={{fontSize:'20px'}}/>
              </div>

              <div className="" >
                <h3>Chat with us</h3>
              </div>
              <div className=""  style={{textAlign:'center'}}>
                <i>
                  <IoIosArrowForward />
                </i>
              </div>
            </div>
          </div>

          <div className="list">
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: 'repeat(3)',
                gridTemplateColumns:'1fr 3fr 1fr',
                paddingLeft: '1rem',
              }}
            >
              <div className="">
                <BiMailSend style={{fontSize:'20px'}}/>
              </div>

              <div className="">
                <h3>Mail Us</h3>
              </div>
              <div className=""  style={{textAlign:'center'}}>
                <i>
                  <IoIosArrowForward />
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
