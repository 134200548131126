import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import AddMoneyPop from "../component/modal/AddMoneyPop";
import { IoWallet } from "react-icons/io5";
import Chart from "../component/list/Chart";
import deposit from "../img/deposit.png";
import withdrawal from "../img/withdrawal.png";
import { BsArrowBarDown, BsArrowBarUp } from "react-icons/bs";
import useRazorpay from "react-razorpay";
import axios from "axios";
import {
  RAZORPAY_GENERATE_ORDER_ID,
  WALLET_FETCH_LIST,
  RAZORPAY_RESPONSE_ORDER_ID,
  WALLET_GENERATE_ORDER_ID,
  WALLET_RESPONSE_PAYMENT_ID,
  REQUEST_WALLET_WITHDRAWAL_AMOUNT,
} from "../utils/api_services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import "../styles/component.css";
import { Input } from "reactstrap";
import { GrDiamond } from "react-icons/gr";
import { setPageLoading } from "../store/actions/authActions";
export default function Wallet() {
  const [lottarydata, setLottaryData] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openWithdrawalModel, setOpenWithdrawalModel] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const [upiNumber, setUpiNumber] = useState("");
  const [upiName, setUpiName] = useState("");
  const [deposit, setDeposit] = useState(null);
  const [withdrawal, setWithdrawal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputWithdrawal, setInputWithdrawal] = useState(0);
  const [amount, setAmount] = useState(0);
  const [walletBalanced, setWalletBalanced] = useState(0);
  const [Razorpay] = useRazorpay();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth.userProfile);
  useEffect(() => {
    fetchWallet();
  }, []);
  const fetchWallet = async () => {
    dispatch(setPageLoading(true));
    try {
      const token = localStorage.getItem("RoyalGmAccessToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(WALLET_FETCH_LIST, config);
      const res = await response.data;
      setDeposit(res.data.deposits);
      setWithdrawal(res.data.withdrawal);
      setWalletBalanced(res.data.wallet_balance);
      setInputWithdrawal(res.data.wallet_balance);
    } catch (error) {
      console.error("Error verifying token:", error);
    } finally {
      dispatch(setPageLoading(false));
    }
  };
  const createOrder = async () => {
    try {
      const token = localStorage.getItem("RoyalGmAccessToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      var postdata = {
        amount: amount,
      };

      const response = await axios.post(
        WALLET_GENERATE_ORDER_ID,
        postdata,
        config
      );
      const res = await response.data.data;
      return res;
    } catch (err) {
      return false;
    }
  };

  const responsePayment = async (orderid, paymentid) => {
    try {
      const token = localStorage.getItem("RoyalGmAccessToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      var postdata = {
        order_id: orderid,
        payment_id: paymentid,
      };

      const response = await axios.post(
        WALLET_RESPONSE_PAYMENT_ID,
        postdata,
        config
      );
      const res = await response.data;
      toast.success(res.message);
      fetchWallet();
      setOpenModal(false);
    } catch (err) {
      toast.error(err.response?.data?.message ?? "Something Went Wrong!");
      setOpenModal(false);
      return;
    }
  };
  const handlePayment = async (params) => {
    if (amount == 0) {
      toast.error("Please enter or select amount");
      return;
    }
    setOpenModal(false);
    setIsLoading(true);
    const order = await createOrder(params); //  Create order on your backend

    const options = {
      key: order.key, // Enter the Key ID generated from the Dashboard
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Acme Corp",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        responsePayment(
          response.razorpay_order_id,
          response.razorpay_payment_id
        );
      },
      prefill: {
        name: order.name,
        email: "youremail@example.com",
        contact: order.mobile,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      responsePayment(
        response.error.metadata.order_id,
        response.error.metadata.payment_id
      );
    });

    rzp1.open();
    setIsLoading(false);
  };

  const handleWithDrawalRequest = async () => {
    if (inputWithdrawal < 110) {
      toast.error("Min. Withdrawal Limit is : ₹ 110");
      return;
    }
    if (upiName == "") {
      toast.error("Enter UPI account name");
      return;
    }
    if (upiNumber == "") {
      toast.error("Enter UPI Number");
      return;
    }
    setOpenWithdrawalModel(false);
    setIsLoading(true);

    try {
      const token = localStorage.getItem("RoyalGmAccessToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      var postdata = {
        request_amount: inputWithdrawal,
        upi_name: upiName,
        upi_number: upiNumber,
      };

      const response = await axios.post(
        REQUEST_WALLET_WITHDRAWAL_AMOUNT,
        postdata,
        config
      );
      const res = await response.data;
      toast.success(res.message);
      fetchWallet();
      setOpenWithdrawalModel(false);
    } catch (err) {
      toast.error(err.response?.data?.message ?? "Something Went Wrong!");
      setOpenWithdrawalModel(false);
      return;
    }
  };
  return (
    <>
      {/* <div className="backnav">
        <Link to="/home">
          <svg
            height="1.5rem"
            viewBox="0 0 448 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs></defs>
            <path
              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
              style={{ fill: "#ffffff" }}
            ></path>
          </svg>
        </Link>
      </div> */}
      {/************* Deposit & modal Model Code ***************/}
      <Modal
        className="custom-modal add-money"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <ModalDialog
          className="custom-modal-dialog"
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Typography id="nested-modal-title" level="h2">
            Deposite Money in your Game Wallet
          </Typography>
          <label htmlFor="upi_number">Enter Amount</label>
          <Input
            type="tel"
            inputMode="numeric"
            placeholder="₹1000"
            value={amount}
          />
          <Box className="add-instant">
            <Button variant="solid" onClick={() => setAmount("100")}>
              100
            </Button>
            <Button variant="solid" onClick={() => setAmount("250")}>
              250
            </Button>
            <Button variant="solid" onClick={() => setAmount("500")}>
              500
            </Button>
            <Button variant="solid" onClick={() => setAmount("1000")}>
              1000
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="solid"
              className="cancle"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              className="proceed"
              onClick={() => handlePayment()}
            >
              Proceed
            </Button>
          </Box>
        </ModalDialog>
      </Modal>

      {/************* Withdrawal Model Code ***************/}

      <Modal
        className="custom-modal add-money"
        open={openWithdrawalModel}
        onClose={() => setOpenWithdrawalModel(false)}
      >
        <ModalDialog
          className="custom-modal-dialog"
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Typography id="nested-modal-title" level="h2">
            Withdrawal Money in your Game Wallet
          </Typography>
          <p>Current Wallet Balance : ₹{walletBalanced}</p>
          <Input
            type="number"
            step="any"
            inputMode="numeric"
            placeholder="₹1000"
            value={inputWithdrawal}
            min={110}
            onChange={(e) => setInputWithdrawal(e.target.value)}
          />
          <label htmlFor="upi_number">UPI Number</label>
          <Input
            type="text"
            step="any"
            id="upi_number"
            placeholder="test@ybl"
            value={upiNumber}
            onChange={(e) => setUpiNumber(e.target.value)}
          />
          <label htmlFor="upi_name">UPI Account Name</label>
          <Input
            type="text"
            step="any"
            id="upi_name"
            placeholder="Rudra kumar"
            value={upiName}
            onChange={(e) => setUpiName(e.target.value)}
          />
          <span>Note : Min. Withdrawal Limit is : ₹ 110</span>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="solid"
              className="cancle"
              onClick={() => setOpenWithdrawalModel(false)}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              className="proceed"
              onClick={() => handleWithDrawalRequest()}
            >
              Send Request
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
      <div
        id="content"
        className="content_container"
        
      >
        <div className="wallet">
          <div className="wallet-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                marginBottom: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: "24px",
                  fontWeight: "bolder",
                  justifyContent: "space-between",
                }}
              >
                <p>Total Wallet Balance</p>{" "}
                <Link to={'/wallet'}><IoWallet style={{ color: "#ffffff", width: "25px" }} /></Link>
              </div>
              <div>
                <p style={{ fontSize: "28px", fontWeight: "500" }}>
                  ₹{walletBalanced ?? 0}
                </p>
              </div>
            </div>
          </div>
          <div className="wallet-btn-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <button className="btn" onClick={() => setOpenModal(true)}>
                <h4> DEPOSIT</h4>
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <button
                className="btn"
                onClick={() => setOpenWithdrawalModel(true)}
              >
                <h4>WITHDRAWAL</h4>
              </button>
            </div>
          </div>
        </div>

        <h3>
          <span>|</span> <span style={{textTransform:'uppercase'}}>Past Transactions</span>
        </h3>

        <Chart deposit={deposit} withdrawal={withdrawal} />
      </div>
    </>
  );
}
