import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "@mui/material";

export default function MyTickets({my_lottaries}) {
  return (
    <>
      <Table sx={{padding:"5px 0 !important"}}>  
  <thead>
    <tr>
      <th style={{ textAlign: "left", fontWeight: "400" }}>Token No.</th>
      <th style={{ textAlign: "center", fontWeight: "400" }}>Amount</th>
      <th style={{ textAlign: "end", fontWeight: "400" }}>Purchase at</th>
    </tr>
  </thead>
  <tbody>
    {my_lottaries &&
      my_lottaries.map((items, key) => (
        <tr key={key} style={{ borderLeft: "5px solid #870000" }}>
         
          <td style={{ textAlign: "left" }}>
            <p style={{ fontSize: "16px" }}>{items.token_no}</p>
          </td>
          <td style={{ textAlign: "center" }}>
            <p style={{ fontSize: "16px" }}>₹ {items.amount}</p>
          </td>
          <td style={{ textAlign: "end" }}>
            <p style={{ fontSize: "12px" }}> {new Date(items.created_at).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })}{' '} {new Date(items.created_at).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit'
  })}</p>
          </td>
        </tr>
      ))}
  </tbody>
</Table>
    </>
  );
}
