import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Winnercard from "../component/card/Winnercard";
import mobile from "../img/mobile.png";
import "swiper/css";
import { WINNERS } from "../utils/api_services";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setPageLoading } from "../store/actions/authActions";

export default function Winner() {
  const [winnerData, setWinnerData] = useState(null);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchWinners();
  }, []);
  const fetchWinners = async () => {
    dispatch(setPageLoading(true));
    try {
      const token = localStorage.getItem("RoyalGmAccessToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(WINNERS, config);
      const res = await response.data;
      setWinnerData(res.data);
    } catch (error) {
      console.error("Error verifying token", error);
    } finally {
      dispatch(setPageLoading(false));
    }
  };
  return (
    <>
      <div id="content" className="content_container">
        <h4>
          <span>|</span>
          <span style={{ textTransform: "uppercase" }}> LOTTARIES WINNERS</span>
        </h4>

        {winnerData?.length &&
          winnerData.map((item) => <Winnercard fetchWinner={item} />)}
      </div>
    </>
  );
}
