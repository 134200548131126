import { LOGIN_SUCCESS, LOGOUT, PAGE_IS_LOADING, SET_LOADING, SET_USER_PROFILE } from "../constants/authConstants";


const initialState = {
  isAuthenticated: false,
  accessToken: null,
  userProfile: null,
  isLoading: true,
  isPageLoading:false
   // Initialize loading as true
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload,
        isLoading: false, // Set loading to false on successful login
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      };
    case SET_LOADING:
        return {
          ...state,
          isLoading: action.payload,
        };
    case PAGE_IS_LOADING:
        return {
            ...state,
            isPageLoading: action.payload,
          };
    case LOGOUT:
        return {
          ...initialState,
        };
    // ... other cases
    default:
      return state;
  }
};

export default authReducer;