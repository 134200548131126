import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import dp from "../../img/profile.webp";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, FreeMode } from "swiper/modules";

export default function Winnercard({ fetchWinner }) {
  const [isLoading, setIsLoading] = useState(false);
  const lotteryInfo = fetchWinner.lottaryInfo;
  const winnersInfo = fetchWinner.winners;
  function getRankSup(rank) {
    rank = parseInt(rank);
    if (rank === 1) {
      return <sup>st</sup>;
    } else if (rank === 2) {
      return <sup>nd</sup>;
    } else if (rank === 3) {
      return <sup>rd</sup>;
    } else if (rank >= 4 && rank <= 10) {
      return <sup>th</sup>;
    }
    return null;
  }
  return (
    <>
      <div className="content " style={{ width: "98%" }}>
        <div
          style={{
            padding: ".3rem 1rem",
            borderBottom: "1px solid #d0d0d0",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p>Prize Pool</p>
          <p>{lotteryInfo.dateTime}</p>
        </div>
        <div className="c1">
          <div>
            <p style={{ fontSize: 15, fontWeight: 700 }}>
              {lotteryInfo.name} <br />
              <span>₹ {lotteryInfo.pricePool}</span>
            </p>
          </div>

          <div style={{ display: "contents" }}>
            <img src={lotteryInfo.img} alt="" />
          </div>
        </div>

        <Swiper
          slidesPerView={2.5}
          spaceBetween={1}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
        >
          {winnersInfo &&
            winnersInfo.map((row, key) => (
              <SwiperSlide>
                <div key={key} className="card-slide">
                  <div className="wnr_crd">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: ".5rem",
                      }}
                    >
                      <img
                        style={{ width: "65%" }}
                        className="user-img"
                        src={row.image}
                        alt=""
                      />
                    </div>
                    <p style={{ textAlign: "center" }}>{row.name}</p>
                    <div style={{ padding: "0 .5rem" }}>
                      <p>
                        {row.rank}
                        <sup>{getRankSup(row.rank)}</sup> Price Won
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "12px" ,
                        justifyContent: "center",
                        padding: "0 1rem",
                        background: "#d1f2ff",
                        borderRadius: 3,
                      }}
                    >
                      <p>Price : ₹{row.winning_amount ?? 0}</p>
                    </div>
                    {row.me ? (
                      <>
                        <div className="meWon">
                          TOKEN : {row.token_no} <br />
                          {row.me}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="meWona">
                          TOKEN :{row.token_no}
                          <br />
                          OTHER WON
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>

        {/* <div className="card-Scrl" style={{

        }}>
         
             
          
        </div> */}
      </div>
    </>
  );
}
