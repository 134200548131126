import { Outlet, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { FiBell } from "react-icons/fi";
import { BiWallet } from "react-icons/bi";

export default function Privacy() {
  const [defaultMode, setDefaultMode] = useState(false);
  const location = useLocation();
  const toggleMode = () => {
    setDefaultMode(!defaultMode);
  };
  const userData = useSelector((state) => state.auth.userProfile);

  return (
    <>
      {/* <div className="nav">
        <div
          className="nav-left"
          style={{ zIndex: 100, marginLeft: 10, marginTop: 5 }}
        >
          <Link to="/home">
            <svg
              height="1.5rem"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs></defs>
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                style={{ fill: "#ffffff" }}
              ></path>
            </svg>
          </Link>
        </div>

      </div> */}
      <PerfectScrollbar>
        <header className="header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gridGap: "1rem",
            }}
          >
            <Link to="/">
            <svg
              height="1.5rem"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs></defs>
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                style={{ fill: "#ffffff" }}
              ></path>
            </svg>
          </Link>

             <h3 style={{ fontWeight: "700" }}>ROYAL LOTTARY</h3>
          </div>

          <div className="nav">
            <div>
              <Link to="/notification">
                <div>
                  <span>
                    <FiBell
                      style={{
                        width: "30px",
                        height: "25px",
                        margin: "0 10px 0 0",
                      }}
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/wallet">
                <div>
                  <span>
                    <BiWallet
                      style={{
                        width: "30px",
                        height: "25px",
                        margin: "0 0 0 10px",
                      }}
                    />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </header>

        <div id="content" className="content_container">
          <div style={{padding:"0 1.5rem"}}>
            <span>
              <h2> Privacy Policy </h2>
              <p>
                
              Effective Date: [Insert Date]
              <br />
              <br />
              At “ROYAL LOTTARY”, we value your privacy and are committed to
              protecting your personal information. This Privacy Policy is
              designed to help you understand how we collect, use, disclose, and
              safeguard your information when you use our app (“ROYAL LOTTARY”).
              By accessing or using the App, you consent to the practices
              described in this Privacy Policy.
              <br />
              <br />
              1. Information We Collect
              <br />
              a. Personal Information: We may collect and store the following
              personal information:
              <br />
              - Name
              <br />
              - Email address
              <br />
              - Postal address
              <br />
              - Phone number
              <br />
              - Payment information (e.g., credit card details)
              <br />
              <br />
              b. Non-Personal Information:
              <br />
              We may also collect non-personal information, which does not
              directly identify you, such as:
              <br />
              - Device information (e.g., device type, operating system, and
              version)
              <br />
              - App usage information (e.g., pages visited, features used, and
              interactions)
              <br />
              - Log data (e.g., IP address, access times, and browser type)
              <br />
              - Cookies and other tracking technologies
              <br />
              <br />
              <br />
              2. How We Use Your Information
              <br />
              <br />
              <br />
              We use the information collected for the following purposes:
              <br />
              - To provide and maintain the App's functionality and services
              <br />
              - To process lottery ticket purchases and distribute winnings
              <br />
              - To communicate with you regarding your account, transactions,
              and notifications
              <br />
              - To improve the App, personalize your experience, and enhance
              user satisfaction
              <br />
              - To send marketing communications and promotions with your
              consent
              <br />
              - To prevent fraudulent activity, enforce our terms, and comply
              with legal requirements
              <br />
              <br />
              <br />
              3. Disclosure of Your Information
              <br />
              <br />
              We may share your information under the following circumstances:
              <br />
              - With service providers, vendors, or contractors who assist us in
              providing the App's services
              <br />
              - With law enforcement, regulatory authorities, or as required by
              applicable laws and regulations
              <br />
              - In the event of a merger, acquisition, or other business
              transaction, your information may be transferred to a third party
              <br />
              - With your consent or as otherwise described when we collect the
              information
              <br />
              <br />
              4. Data Security
              <br />
              <br />
              We employ reasonable security measures to protect your information
              from unauthorized access, disclosure, alteration, or destruction.
              However, no method of transmission over the internet or electronic
              storage is completely secure, and we cannot guarantee absolute
              security.
              <br />
              <br />
              5. Your Choices
              <br />
              <br />
              You have certain rights regarding your personal information:
              <br />
              - You may access, correct, or delete your personal information
              through your account settings.
              <br />
              - You may opt-out of marketing communications by following the
              instructions provided in such communications.
              <br />
              - You may disable cookies or adjust your device settings to limit
              data collection.
              <br />
              <br />
              - You may contact us to request information on how your data is
              used or to exercise other rights granted by applicable data
              protection laws.
              <br />
              <br />
              6. Children's Privacy
              <br />
              <br />
              The App is not intended for use by individuals under the age of
              18. We do not knowingly collect personal information from
              children. If you are a parent or guardian and believe your child
              has provided us with personal information, please contact us to
              have it removed.
              <br />
              <br />
              7. Changes to this Privacy Policy
              <br />
              <br />
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. We will notify you of any substantial changes
              through the App or other means.
              <br />
              <br />
              8. Contact Us
              <br />
              <br />
              If you have any questions, concerns, or requests related to this
              Privacy Policy, please contact us at [Insert Contact Information].
              <br />
              By using our App, you acknowledge that you have read and agree to
              this Privacy Policy. It is your responsibility to review this
              policy periodically for any changes.
         
              </p>
              </span>
          </div>
        </div>

       
      </PerfectScrollbar>
    </>
  );
}
