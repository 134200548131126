import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { Tab, Table, TabList, TabPanel, Tabs } from "@mui/joy";
import { FiBell } from "react-icons/fi";
import { BiWallet } from "react-icons/bi";
import { IoWallet, IoRefresh } from "react-icons/io5";
import BetMoney from "../modal/BetMoney";
import { SINGLE_GAME_APPLY, SINGLE_GAME_LIST } from "../../utils/api_services";
import axios from "axios";
import "../../styles/animate.css";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import "../../styles/component.css";
import { Input } from "reactstrap";
import toast from "react-hot-toast";


function createData(Period, Number, Big_Small, Num_Chart) {
  return { Period, Number, Big_Small, Num_Chart };
}

const rows = [
  createData("235689784512", 1),
  createData("235689784511", 8),
  createData("235689784510", 9),
  createData("235689784509", 5),
  createData("235689784508", 1),
];

export default function Minigame() {
  const [openModal, setOpenModal] = React.useState(false);
  const [rand, setRand] = useState([]);

  const [fetchWinningList, setFetchWinningList] = useState(null);
  const [walletBalance, setWalletBalance] = useState(0);

  const [loading, setLoading] = useState(false);
  const [isApplyBet, setIsApplyBet] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [betAmount, setBetAmount] = useState(0);
  const [betNumber, setBetNumber] = useState(0);

  const handleSpin = async () => {
    setRotation(0);
    setOpenModal(false);
    setIsApplyBet(true);
    try {
      const token = localStorage.getItem("RoyalGmAccessToken");

      if (betNumber == 0 || betAmount == 0) {
        toast.error("Amount and Bet Number is missing!");
        return;
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        SINGLE_GAME_APPLY,
        {
          bet_number: betNumber,
          bet_amount: betAmount,
        },
        config
      );
      const res = await response.data;
      const betNo = res.data.game_id;

      setRotation(res.data.degree);
      toast.success("Please Wait...");

      setTimeout(() => {
        fetchLottary();
        toast.success("result is announced! ↓↓↓↓");
      }, 5000);
    } catch (err) {
      toast.error(err.response.data.message ?? "Something Went Wrong!");
    } finally {
      setIsApplyBet(false);
      setBetAmount(0);
      setBetNumber(0);
    }
  };

  useEffect(() => {
    fetchLottary();
  }, []);
  const fetchLottary = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("RoyalGmAccessToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(SINGLE_GAME_LIST, config);
      const res = await response.data;

      setFetchWinningList(res.data.winnerList);
      setWalletBalance(res.data.wallet_balance);
    } catch (error) {
      setFetchWinningList(null);
      setWalletBalance(0);
    } finally {
      setLoading(false);
    }
  };

  const openAndSetBetNumber = (num) => {
    setBetNumber(num);
    setOpenModal(true);
  };
  // useEffect(() => {
  //   setRand([]);
  //   var data = [];
  //   for (let index = 1; index <= 20; index++) {
  //     data.push(index);
  //   }

  //   setRand(data);
  // }, []);


  return (
    <>
      <PerfectScrollbar>
      <header className="header">
          <div style={{display:'flex', flexDirection:'row', alignItems:'center',gridGap:'1rem'}}>
           
              <Link to="/home">
                <svg
                  height="1.5rem"
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs></defs>
                  <path
                    d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                    style={{ fill: "#ffffff" }}
                  ></path>
                </svg>
              </Link>
      

             <h3 style={{ fontWeight: "700" }}>ROYAL LOTTARY</h3>
          </div>

          <div className="nav">
            <div>
              <Link to="/">
                <div>
                  <span>
                    <FiBell
                      style={{
                        width: "30px",
                        height: "25px",
                        margin: "0 10px 0 0",
                      }}
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/wallet">
                <div>
                  <span>
                    <BiWallet
                      style={{
                        width: "30px",
                        height: "25px",
                        margin: "0 0 0 10px",
                      }}
                    />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </header>
        <Modal
          className="custom-modal add-money"
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <ModalDialog
            className="custom-modal-dialog"
            aria-labelledby="nested-modal-title"
            aria-describedby="nested-modal-description"
            sx={(theme) => ({
              [theme.breakpoints.only("xs")]: {
                top: "unset",
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 0,
                transform: "none",
                maxWidth: "unset",
              },
            })}
          >
            <Box>
              <Typography id="nested-modal-title" level="h2">
                Make your Bets
              </Typography>
              <Typography id="nested-modal-subtitle" level="h6">
                You selected :- <p> </p>
              </Typography>
            </Box>
            <Input
              type="number"
              step="any"
              inputMode="numeric"
              placeholder="₹1000"
              value={betAmount}
              onChange={(e) => setBetAmount(e.target.value)}
            />
            <Box className="add-instant">
              <Button variant="solid" onClick={() => setBetAmount("100")}>
                100
              </Button>
              <Button variant="solid" onClick={() => setBetAmount("250")}>
                250
              </Button>
              <Button variant="solid" onClick={() => setBetAmount("500")}>
                500
              </Button>
              <Button variant="solid" onClick={() => setBetAmount("1000")}>
                1000
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="solid"
                className="cancle"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                className="proceed"
                onClick={() => handleSpin()}
              >
                Proceed
              </Button>
            </Box>
          </ModalDialog>
        </Modal>

        <div id="content" className="content_container">
          <div className="wallet">
            <div className="wallet-wrapper">
              <div className="wallet-input">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "26px",
                    fontWeight: "bolder",
                    justifyContent: "space-between",
                  }}
                >
                  <p>Total Wallet Balance</p>
                  <IoWallet style={{ width: "25px" }} />
                </div>
                <div>
                  <p style={{ fontSize: 28 }}>
                    {loading ? "Fetching Wallet Balance" : "₹" + walletBalance}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="cntr1">
            <div className="rule-wrapper">
              <div className="rule">
                <h4>How to play</h4>
                <ol>
                  <li>
                    <h4>Place your bet using money form your wallet.</h4>
                  </li>
                  <li>
                    <h4>Wheel spins automatically after bet.</h4>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="main-game">
            <div className="wheel gold">
              <div className="border">
                <div class="wheel-content">
                  <div
                    className="container"
                    style={{ transform: `rotate(${rotation}deg)` }}
                  >
                    <div className="one">1</div>
                    <div className="two">2</div>
                    <div className="three">3</div>
                    <div className="four">4</div>
                    <div className="five">5</div>
                    <div className="six">6</div>
                    <div className="seven">7</div>
                    <div className="eight">8</div>
                  </div>
                  <button id="spin" onClick={handleSpin} disabled={isApplyBet}>
                    Spin
                  </button>
                </div>
              </div>
              <div id="attributes-arrow" className="pointer" style={{}}></div>
              <div id="attributes-base" className="base" style={{}}></div>
              <div class="decorations">
                {/*  =======  position: absolute;
    width: 2.4%;
    height: 2.4%;
    border-radius: 100%;
    background-image: radial-gradient(circle at 0 0,#fff,#fff 30%,transparent 60%),radial-gradient(circle at 0 0,#ffff34,#ffff34 50%,#000 100%);
    top: 8.2%;
    left: 74.5%;======= */}
                <span class="decoration"></span>
                <span class="decoration"></span>
                <span class="decoration"></span>
                <span class="decoration"></span>
                <span class="decoration"></span>
                <span class="decoration"></span>
                <span class="decoration"></span>
                <span class="decoration"></span>
                <span class="decoration"></span>
                <span class="decoration"></span>
                <span class="decoration"></span>
              </div>
            </div>
            <div className="cntr1">
              <div className="rule-wrapper">
                <div className="rule">
                  <ul>
                    <li>
                      <h4>Betted amount+80% = Winning Amount</h4>
                    </li>
                    <li>
                      <h4>100 + 80 = 180</h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {isApplyBet ? (
              <></>
            ) : (
              <div className="redgreen ">
                <div>
                  <div className="num-screen">
                    <div
                      className="num1"
                      onClick={() => openAndSetBetNumber(1)}
                    >
                      1
                    </div>
                    <div
                      className="num2"
                      onClick={() => openAndSetBetNumber(2)}
                    >
                      2
                    </div>
                    <div
                      className="num3"
                      onClick={() => openAndSetBetNumber(3)}
                    >
                      3
                    </div>
                    <div
                      className="num4"
                      onClick={() => openAndSetBetNumber(4)}
                    >
                      4
                    </div>
                    <div
                      className="num5"
                      onClick={() => openAndSetBetNumber(5)}
                    >
                      5
                    </div>
                    <div
                      className="num6"
                      onClick={() => openAndSetBetNumber(6)}
                    >
                      6
                    </div>
                    <div
                      className="num7"
                      onClick={() => openAndSetBetNumber(7)}
                    >
                      7
                    </div>
                    <div
                      className="num8"
                      onClick={() => openAndSetBetNumber(8)}
                    >
                      8
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="chart">
              <Tabs aria-label="Basic tabs" defaultValue={0}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                  }}
                >
                  <div>
                    <h3>Game History</h3>
                  </div>{" "}
                  <button
                    className="btn"
                    style={{ width: "25%" }}
                    onClick={() => fetchLottary()}
                  >
                    Refresh <IoRefresh />{" "}
                  </button>
                </div>

                <TabPanel
                  value={0}
                  sx={{
                    padding: "1rem .5rem ",
                  }}
                >
                  <Table>
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontSize: "12px",textAlign: "center", fontWeight: "400" 
                          }}
                        >
                          Date/Time
                        </th>
                        <th
                          style={{
                            fontSize: "12px",textAlign: "center", fontWeight: "400" 
                          }}
                        >
                          Amount
                        </th>

                        <th
                          style={{
                            fontSize: "12px",textAlign: "center", fontWeight: "400" 
                          }}
                        >
                          Bet No.
                        </th>
                        <th
                          style={{
                            fontSize: "12px",textAlign: "center", fontWeight: "400" 
                          }}
                        >
                          Win No.
                        </th>
                        <th
                          style={{
                            fontSize: "12px",textAlign: "center", fontWeight: "400" 
                          }}
                        >
                          Win(Rs.)
                        </th>
                        <th
                          style={{
                            fontSize: "12px",textAlign: "center", fontWeight: "400" 
                          }}
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p style={{ textAlign: "center" }}>Please Wait...</p>
                        </div>
                      ) : (
                        <>
                          {fetchWinningList &&
                            fetchWinningList.map((row) => (
                              <tr key={row.Period}>
                                <td style={{ fontSize: 10 }}>
                                  {" "}
                                  {new Date(row.created_at).toLocaleDateString(
                                    "en-GB",
                                    {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    }
                                  )}{" "}
                                  {new Date(row.created_at).toLocaleTimeString(
                                    "en-US",
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }
                                  )}
                                </td>
                                <td>₹ {row.bet_amount}</td>
                                <td>{row.bet_number}</td>
                                <td>{row.win_number}</td>
                                <td>₹ {row.win_amount}</td>
                                <td>
                                  {row.status === "WIN" ? (
                                    <span
                                      style={{
                                        backgroundColor: "green",
                                        color: "white",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      WIN
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      LOSS
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </Table>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </>
  );
}
