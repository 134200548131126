import { Outlet, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { FiBell } from "react-icons/fi";
import { BiWallet } from "react-icons/bi";
import dp from "../../img/profile.webp";

export default function MainLayout() {
  const [defaultMode, setDefaultMode] = useState(false);
  const location = useLocation();
  const toggleMode = () => {
    setDefaultMode(!defaultMode);
  };
  const userData = useSelector((state) => state.auth.userProfile);

  return (
    <>
      <PerfectScrollbar>
        <header className="header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gridGap: "1rem",
            }}
          >
            <Link to="/profile">
              <div className="center" style={{ cursor: "pointer" }}>
                <img
                  className="user-img"
                  style={{ width: "30px" }}
                  src={dp}
                  alt="profile"
                />
              </div>
            </Link>
             <h3 style={{ fontWeight: "700" }}>ROYAL LOTTARY</h3>
          </div>

          <div className="nav">
            <div>
              <Link to="/notification">
                <div>
                  <span>
                    <FiBell
                      style={{
                        width: "30px",
                        height: "25px",
                        margin: "0 10px 0 0",
                      }}
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/wallet">
                <div>
                  <span>
                    <BiWallet
                      style={{
                        width: "30px",
                        height: "25px",
                        margin: "0 0 0 10px",
                      }}
                    />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </header>

        <div>
          <Outlet />
        </div>
        <div id="main_tab" className="tabbar_container">
          <nav className="tabbar">
            <ul>
              <li
                className={
                  location.pathname == "/" || location.pathname == "/home"
                    ? "active"
                    : ""
                }
              >
                <Link to="/home">
                  <div>
                    <span>
                      <svg>
                        <use xlinkHref="#dashboardIcon" />
                      </svg>
                    </span>
                    <span>
                      <svg>
                        <use xlinkHref="#dashboardIconFilled" />
                      </svg>
                    </span>
                  </div>
                  <strong>Home</strong>
                </Link>
              </li>
              <li className={location.pathname == "/wallet" ? "active" : ""}>
                <Link to="/wallet">
                  <div>
                    <span>
                      <svg>
                        <use xlinkHref="#walletIcon" />
                      </svg>
                    </span>
                    <span>
                      <svg>
                        <use xlinkHref="#walletIconFilled" />
                      </svg>
                    </span>
                  </div>
                  <strong>Wallet</strong>
                </Link>
              </li>
              <li className={location.pathname == "/mygames" ? "active" : ""}>
                <Link to="/mygames">
                  <div>
                    <span>
                      <svg>
                        <use xlinkHref="#gamesIcon" />
                      </svg>
                    </span>
                    <span>
                      <svg>
                        <use xlinkHref="#gamesIconFilled" />
                      </svg>
                    </span>
                  </div>
                  <strong>My Lottary</strong>
                </Link>
              </li>
              <li className={location.pathname == "/winner" ? "active" : ""}>
                <Link to="/winner">
                  <div>
                    <span>
                      <svg>
                        <use xlinkHref="#crownIcon" />
                      </svg>
                    </span>
                    <span>
                      <svg>
                        <use xlinkHref="#crownIconFilled" />
                      </svg>
                    </span>
                  </div>
                  <strong>Winnings</strong>
                </Link>
              </li>
            </ul>
          </nav>

          <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
            <symbol
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="dashboardIcon"
            >
              <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2">
                <path
                  fill="none"
                  strokeMiterlimit="10"
                  d="M6.3,10C6.1,10.6,6,11.3,6,12"
                  strokeLinecap="butt"
                ></path>
                <path
                  fill="none"
                  strokeMiterlimit="10"
                  d="M18,12c0-3.3-2.7-6-6-6 c-0.7,0-1.4,0.1-2,0.3"
                  strokeLinecap="butt"
                ></path>
                <line
                  fill="none"
                  strokeMiterlimit="10"
                  x1="10.6"
                  y1="10.6"
                  x2="7"
                  y2="7"
                ></line>
                <circle
                  fill="none"
                  strokeMiterlimit="10"
                  cx="12"
                  cy="12"
                  r="2"
                ></circle>
                <circle
                  fill="none"
                  strokeMiterlimit="10"
                  cx="12"
                  cy="12"
                  r="11"
                ></circle>
              </g>
            </symbol>
            <symbol
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="dashboardIconFilled"
            >
              <path
                stroke="none"
                d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M5,12c0,0.552-0.447,1-1,1 s-1-0.448-1-1c0-1.387,0.325-2.696,0.887-3.871l1.526,1.526C5.15,10.389,5,11.177,5,12z M12,15c-1.654,0-3-1.346-3-3 c0-0.462,0.113-0.894,0.3-1.285L5.086,6.5L6.5,5.086l4.214,4.215C11.106,9.114,11.538,9,12,9c1.654,0,3,1.346,3,3S13.654,15,12,15z M20,13c-0.553,0-1-0.448-1-1c0-3.86-3.141-7-7-7c-0.823,0-1.61,0.15-2.345,0.413L8.13,3.887C9.304,3.324,10.613,3,12,3 c4.963,0,9,4.038,9,9C21,12.552,20.553,13,20,13z"
              ></path>
            </symbol>
            <symbol
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="walletIcon"
            >
              <g
                strokeLinecap="square"
                strokeLinejoin="miter"
                strokeWidth="2"
                transform="matrix(1.297297, 0, 0, 1.297297, -3.891892, -3.567568)"
              >
                <path
                  d="M15.5 13.75C15.5 13.3358 15.8358 13 16.25 13H18.25C18.6642 13 19 13.3358 19 13.75C19 14.1642 18.6642 14.5 18.25 14.5H16.25C15.8358 14.5 15.5 14.1642 15.5 13.75ZM3 5H3.01373C3.13809 3.87501 4.09186 3 5.25 3H16.75C17.9926 3 19 4.00736 19 5.25V6.08697C20.4333 6.42555 21.5 7.71321 21.5 9.25V17.75C21.5 19.5449 20.0449 21 18.25 21H6.25C4.45508 21 3 19.5449 3 17.75V5ZM18.25 7.5H4.5V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H18.25C19.2165 19.5 20 18.7165 20 17.75V9.25C20 8.2835 19.2165 7.5 18.25 7.5ZM17.5 6V5.25C17.5 4.83579 17.1642 4.5 16.75 4.5H5.25C4.83579 4.5 4.5 4.83579 4.5 5.25C4.5 5.66421 4.83579 6 5.25 6H17.5Z"
                  style={{ strokeWidth: 0 }}
                ></path>
              </g>
            </symbol>
            <symbol
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="walletIconFilled"
            >
              <g stroke="none">
                <rect x="3" y="1" width="6" height="2"></rect>
                <path
                  d="M 3 -0.024 C 1.345 -0.024 0 1.516 0 3.412 L 0 20.589 C 0 22.484 1.345 24.024 3 24.024 L 21 24.024 C 22.655 24.024 24 22.484 24 20.589 L 24 8.564 C 24 6.669 22.655 5.13 21 5.13 L 3.75 5.13 C 3.338 5.13 3 4.742 3 4.27 C 3 3.797 3.338 3.412 3.75 3.412 L 19.19 3.412 C 20.02 3.412 20.833 2.699 20.833 1.749 C 20.833 0.798 20.02 -0.024 19.19 -0.024 L 3 -0.024 Z M 19.5 12.859 C 20.655 12.859 21.376 14.29 20.799 15.436 C 20.531 15.966 20.036 16.294 19.5 16.294 C 18.345 16.294 17.624 14.863 18.201 13.718 C 18.469 13.187 18.964 12.859 19.5 12.859 Z"
                  style={{ strokeWidth: 0 }}
                ></path>
              </g>
            </symbol>
            <symbol
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="gamesIcon"
            >
              <g transform="matrix(1.14966, 0, 0, 1.432851, -1.769299, -5.167577)">
                <path
                  d="M14.9979 5C18.8639 5 21.9979 8.13401 21.9979 12C21.9979 15.7855 18.9931 18.8691 15.2385 18.9959L14.9979 19H9.00211C5.13611 19 2.00211 15.866 2.00211 12C2.00211 8.21455 5.00689 5.1309 8.76146 5.00406L9.00211 5H14.9979ZM14.9979 6.5H9.00211C5.96454 6.5 3.50211 8.96243 3.50211 12C3.50211 14.9635 5.84589 17.3795 8.7809 17.4956L9.00211 17.5H14.9979C18.0354 17.5 20.4979 15.0376 20.4979 12C20.4979 9.03652 18.1541 6.62046 15.2191 6.50437L14.9979 6.5ZM7.99999 9C8.4142 9 8.74999 9.33579 8.74999 9.75L8.74911 11.248L10.25 11.2487C10.6642 11.2487 11 11.5845 11 11.9987C11 12.413 10.6642 12.7487 10.25 12.7487L8.74911 12.748L8.74999 14.25C8.74999 14.6642 8.4142 15 7.99999 15C7.58578 15 7.24999 14.6642 7.24999 14.25L7.24911 12.748L5.74999 12.7487C5.33578 12.7487 4.99999 12.413 4.99999 11.9987C4.99999 11.5845 5.33578 11.2487 5.74999 11.2487L7.24911 11.248L7.24999 9.75C7.24999 9.33579 7.58578 9 7.99999 9ZM14.75 12.5C15.4403 12.5 16 13.0596 16 13.75C16 14.4404 15.4403 15 14.75 15C14.0596 15 13.5 14.4404 13.5 13.75C13.5 13.0596 14.0596 12.5 14.75 12.5ZM16.75 9C17.4403 9 18 9.55964 18 10.25C18 10.9404 17.4403 11.5 16.75 11.5C16.0596 11.5 15.5 10.9404 15.5 10.25C15.5 9.55964 16.0596 9 16.75 9Z"
                  style={{ strokeWidth: 0 }}
                ></path>
              </g>
            </symbol>
            <symbol
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="gamesIconFilled"
            >
              <g transform="translate(1,0)" stroke="none">
                <path
                  d="M 6.975 2.001 C 3.118 2.001 -0.01 6.515 -0.01 12.076 C -0.01 17.638 3.118 22.15 6.975 22.15 L 16.291 22.15 C 20.146 22.15 23.275 17.638 23.275 12.076 C 23.275 6.515 20.146 2.001 16.291 2.001 L 6.975 2.001 Z M 18.036 7.459 C 19.157 7.459 19.856 9.206 19.296 10.607 C 19.036 11.256 18.557 11.656 18.036 11.656 C 16.915 11.656 16.216 9.907 16.775 8.508 C 17.036 7.857 17.514 7.459 18.036 7.459 Z M 14.252 14.593 C 14.252 12.977 15.466 11.969 16.435 12.776 C 16.885 13.151 17.163 13.844 17.163 14.593 C 17.163 16.21 15.948 17.219 14.979 16.413 C 14.528 16.037 14.252 15.344 14.252 14.593 Z M 6.101 9.137 C 6.101 8.439 6.491 7.878 6.975 7.878 C 7.459 7.878 7.848 8.439 7.848 9.137 L 7.848 10.816 L 9.011 10.816 C 9.497 10.816 9.886 11.376 9.886 12.076 C 9.886 12.774 9.497 13.335 9.011 13.335 L 7.848 13.335 L 7.848 15.014 C 7.848 15.712 7.459 16.273 6.975 16.273 C 6.491 16.273 6.101 15.712 6.101 15.014 L 6.101 13.335 L 4.939 13.335 C 4.454 13.335 4.064 12.774 4.064 12.076 C 4.064 11.376 4.454 10.816 4.939 10.816 L 6.101 10.816 L 6.101 9.137 Z"
                  style={{ strokeWidth: 0 }}
                ></path>
              </g>
            </symbol>
            <symbol
              viewBox="0 0 25 25"
              xmlns="http://www.w3.org/2000/svg"
              id="crownIconFilled"
            >
              <defs></defs>
              <path d="M 24.614 5.584 L 18.532 9.11 L 12.7 1.789 C 12.599 1.662 12.398 1.662 12.297 1.789 L 6.469 9.11 L 0.383 5.584 C 0.205 5.48 -0.026 5.619 0.002 5.816 L 2.39 22.894 C 2.424 23.126 2.637 23.306 2.89 23.306 L 22.113 23.306 C 22.363 23.306 22.579 23.129 22.61 22.894 L 24.998 5.816 C 25.023 5.619 24.795 5.48 24.614 5.584 Z M 12.5 19.047 C 10.559 19.047 8.981 17.561 8.981 15.735 C 8.981 13.908 10.559 12.423 12.5 12.423 C 14.441 12.423 16.019 13.908 16.019 15.735 C 16.019 17.561 14.441 19.047 12.5 19.047 Z M 12.5 14.314 C 11.669 14.314 10.994 14.949 10.994 15.735 C 10.994 16.517 11.669 17.155 12.5 17.155 C 13.332 17.155 14.006 16.52 14.006 15.735 C 14.006 14.952 13.332 14.314 12.5 14.314 Z"></path>
            </symbol>
            <symbol
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="crownIcon"
            >
              <defs></defs>
              <path d="M 23.629 4.945 L 17.791 8.543 L 12.192 1.075 C 12.096 0.946 11.902 0.946 11.805 1.075 L 6.21 8.543 L 0.368 4.945 C 0.197 4.84 -0.025 4.981 0.002 5.182 L 2.294 22.602 C 2.327 22.839 2.531 23.022 2.774 23.022 L 21.229 23.022 C 21.469 23.022 21.676 22.842 21.706 22.602 L 23.998 5.182 C 24.022 4.981 23.803 4.84 23.629 4.945 Z M 19.849 20.97 L 4.149 20.97 L 2.534 8.687 L 6.729 11.27 L 12 4.237 L 17.272 11.27 L 21.466 8.687 L 19.849 20.97 Z M 12 11.921 C 10.137 11.921 8.622 13.436 8.622 15.299 C 8.622 17.162 10.137 18.677 12 18.677 C 13.863 18.677 15.378 17.162 15.378 15.299 C 15.378 13.436 13.863 11.921 12 11.921 Z M 12 16.748 C 11.202 16.748 10.554 16.1 10.554 15.299 C 10.554 14.501 11.202 13.85 12 13.85 C 12.798 13.85 13.446 14.498 13.446 15.299 C 13.446 16.097 12.798 16.748 12 16.748 Z"></path>
            </symbol>
          </svg>
        </div>
      </PerfectScrollbar>
    </>
  );
}
