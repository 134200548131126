import React, { useState, useEffect, useCallback } from "react";
import "swiper/css";
import { Tab, Table, TabList, TabPanel, Tabs, tabClasses } from "@mui/joy";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FiBell } from "react-icons/fi";
import { BiWallet } from "react-icons/bi";
import Cashlottery from "../component/card/Cashlottery";
import Tangiblelottery from "../component/card/TangibleLottery";
import Winnings from "../component/winnings/Winnings";
import { Link, useLocation } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import useRazorpay from "react-razorpay";
import RModel from "../component/modal/Modal";
import axios from "axios";
import {
  RAZORPAY_GENERATE_ORDER_ID,
  SINGLE_LOTTARY_DATA,
  RAZORPAY_RESPONSE_ORDER_ID,
} from "../utils/api_services";
import MyTickets from "../component/winnings/MyTickets";
import toast from "react-hot-toast";
import { setPageLoading } from "../store/actions/authActions";
import { useDispatch } from "react-redux";

export default function Lottery(props) {
  const [openModal, setOpenModal] = React.useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryValue = searchParams.get("query");
  const [lottarydata, setLottaryData] = useState(null);
  const [checkbox, setCheckbox] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [isSlotfull, setIsSlotfull] = useState(false);

  const [Razorpay] = useRazorpay();
  useEffect(() => {
    fetchLottary();
  }, []);

  useEffect(() => {
    //   var total = item.target_user || 0;
    //   var joined = item.joined_users || 0;
    // if(joined >= total)
    // {
    //   setIsSlotfull(true);
    // }
  }, []);

  const fetchLottary = async () => {
    dispatch(setPageLoading(true));
    try {
      const token = localStorage.getItem("RoyalGmAccessToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        SINGLE_LOTTARY_DATA + queryValue,
        config
      );
      const res = await response.data;
      setLottaryData(res.data);
      var total = parseInt(res.data.target_user || 0);
      var joined = parseInt(res.data.joined_users || 0);
      
      if (joined >= total) {
        setIsSlotfull(true);
      }else{
        setIsSlotfull(false);
      }
    } catch (error) {
      console.error("Error verifying token:", error);
    } finally {
      dispatch(setPageLoading(false));
    }
  };

  const createOrder = async () => {
    try {
      const token = localStorage.getItem("RoyalGmAccessToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      var postdata = {
        id: lottarydata.id,
      };

      const response = await axios.post(
        RAZORPAY_GENERATE_ORDER_ID,
        postdata,
        config
      );
      const res = await response.data.data;
      return res;
    } catch (err) {
      return false;
    }
  };

  const responsePayment = async (orderid, paymentid) => {
    try {
      const token = localStorage.getItem("RoyalGmAccessToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      var postdata = {
        order_id: orderid,
        payment_id: paymentid,
      };

      const response = await axios.post(
        RAZORPAY_RESPONSE_ORDER_ID,
        postdata,
        config
      );
      const res = await response.data;
      toast.success(res.message);
      setCheckbox(false);
      fetchLottary();
    } catch (err) {
      toast.error(err.response?.data?.message ?? "Something Went Wrong!");
      return;
    }
  };
  const handlePayment = async (params) => {
    if (checkbox != true) {
      toast.error("Please check the terms and conditions!");
      return false;
    }
    setIsLoading(true);
    const order = await createOrder(params); //  Create order on your backend
   
    const options = {
      key: order.key, // Enter the Key ID generated from the Dashboard
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Acme Corp",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        responsePayment(
          response.razorpay_order_id,
          response.razorpay_payment_id
        );
      },
      prefill: {
        name: order.name,
        email: "youremail@example.com",
        contact: order.mobile,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      responsePayment(
        response.error.metadata.order_id,
        response.error.metadata.payment_id
      );
    });

    rzp1.open();
    setIsLoading(false);
  };
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const checkboxValue = event.target.value;
    setCheckbox(isChecked ? checkboxValue : null);
  };

  return (
    <>
      <PerfectScrollbar>
        <header className="header">
          <div style={{display:'flex', flexDirection:'row', alignItems:'center',gridGap:'1rem'}}>
           
              <Link to="/home">
                <svg
                  height="1.5rem"
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs></defs>
                  <path
                    d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                    style={{ fill: "#ffffff" }}
                  ></path>
                </svg>
              </Link>
      

             <h3 style={{ fontWeight: "700" }}>ROYAL LOTTARY</h3>
          </div>

          <div className="nav">
            <div>
              <Link to="/notification">
                <div>
                  <span>
                    <FiBell
                      style={{
                        width: "30px",
                        height: "25px",
                        margin: "0 10px 0 0",
                      }}
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/wallet">
                <div>
                  <span>
                    <BiWallet
                      style={{
                        width: "30px",
                        height: "25px",
                        margin: "0 0 0 10px",
                      }}
                    />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </header>

        {lottarydata && (
          <>
            <RModel open={openModal} setOpen={setOpenModal} />
            <div
              id="content"
              className="content_container"
              style={{ marginTop: "3rem" }}
            >
              {lottarydata.type === "PRIZE_WINNING" ? (
                <Cashlottery item={lottarydata} show={true} />
              ) : (
                <Tangiblelottery item={lottarydata} />
              )}

              <div
                className="content"
                style={{ borderRadius: 5, padding: "5px" }}
              >
                <Tabs
                  aria-label="Basic tabs"
                  defaultValue={0}
                  sx={{
                    background: "#fff",
                  }}
                >
                  <TabList
                    sx={{
                      mt: 1,
                      display: "flex",
                      gap: 0,
                      flexDirection: "row",
                      justifyContent: "space-around",
                      p: 0.5,
                      gap: 1,
                      borderBottom: "1px light #d0d0d0",
                      bgcolor: "#fff",
                      [`& .${tabClasses.root}[aria-selected="true"]`]: {
                        color: "#870000",
                        bgcolor: "#fff",
                      },
                    }}
                  >
                    <Tab sx={{ padding: "5px" }} indicatorInset>
                      <span style={{ fontSize: "14px", fontWeight: "600" }}>
                        About
                      </span>
                    </Tab>
                    <Tab sx={{ padding: "5px" }} indicatorInset>
                      <span style={{ fontSize: "14px", fontWeight: "600" }}>
                        Prizes
                      </span>
                    </Tab>
                    <Tab sx={{ padding: "5px" }} indicatorInset>
                      <span style={{ fontSize: "14px", fontWeight: "600" }}>
                        My Tokens
                      </span>
                    </Tab>
                  </TabList>

                  <TabPanel background="#fff" value={0}>
                    <div className="lucky-draw">
                      <div className="c1">
                        <div className="">
                          <h2>
                            Lucky Draw : <span>{lottarydata.name} </span>
                          </h2>
                          <h4>Get a Chance to win Bumper Prize</h4>
                          <p>{lottarydata.lottary_desc}</p>
                        </div>
                      </div>
                      <div className="c1">
                        <div className="">
                          <h4>Rules</h4>
                          <ul>
                            <li>{lottarydata.lottary_rules}</li>
                          </ul>
                        </div>
                      </div>
                      <div className="c1">
                        <div className="">
                          <h4>Terms and Comditions</h4>
                          <p>
                           
                          {lottarydata.details}
                          </p>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                    sx={{ padding: "1rem 0" }}
                    background="#fff"
                    value={1}
                  >
                    <Winnings lottarydata={lottarydata} />
                  </TabPanel>
                  <TabPanel background="#fff" value={2}>
                    <MyTickets my_lottaries={lottarydata.my_lottaries} />
                  </TabPanel>
                  <TabPanel background="#fff" value={3}>
                    No data available yet
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div className="join-btn-wrapper">
              {isSlotfull ? (
                <div className="join-btn">
                  {lottarydata.status != 'PENDING' ? <>
                  <p style={{ fontSize: "16px" }}> Lottary are closed! </p>

<button type="button" className="btn" disabled>
Tickets are sold!
</button>
                  </> : <>
                  <p style={{ fontSize: "16px" }}> All Tickets are sold  </p>

<button type="button" className="btn " style={{backgroundColor:'#c95267'}} disabled>
  No More Tickets Are Available!
</button></>}
                 
                </div>
              ) : (
                <div className="join-btn">
                  <p>
                    <input
                      type="checkbox"
                      name=""
                      id="terms"
                      value={1}
                      disabled={isSlotfull}
                      onChange={(value) => handleCheckboxChange(value)}
                    />{" "}
                    I agree with all the{" "}
                    <span htmlFor="terms">terms and conditions</span>,
                  </p>
                  {isLoading ? (
                    <button type="button" className="btn" disabled>
                      Processing...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn"
                      onClick={handlePayment}
                      disabled={isSlotfull}
                    >
                      JOIN ₹{lottarydata.lottary_fees}
                    </button>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </PerfectScrollbar>
    </>
  );
}
