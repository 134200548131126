import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "@mui/material";

export default function Winnings({lottarydata}) {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th style={{textAlign:"left", fontWeight:"400"}}>Rank</th>
            <th style={{textAlign:"center", fontWeight:"400"}}>Candidates</th>
            <th style={{textAlign:"end", fontWeight:"400"}}>Amount ₹</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{borderLeft:"5px solid #870000"}}>
            <td>
              <p>1<sup>st</sup> Rank</p>
            </td>
            <td style={{textAlign:"center"}}>
              <p style={{ fontSize: "16px" }}> {lottarydata.lottary_first_win_users}</p>
            </td>
        
            <td style={{textAlign:"end"}}>
              <p style={{ fontSize: "16px" }}>₹ {lottarydata.lottary_first_win_price}</p>
            </td>
          </tr>
         {lottarydata.lottary_second_win_price &&
          <tr style={{borderLeft:"5px solid #870000"}}>
            <td>
              <p>2<sup>nd</sup> Rank</p>
            </td>
            <td style={{textAlign:"center"}}>
              <p style={{ fontSize: "16px" }}> {lottarydata.lottary_second_win_users}</p>
            </td>
        
            <td style={{textAlign:"end"}}>
              <p style={{ fontSize: "16px" }}> ₹ {lottarydata.lottary_second_win_price}</p>
            </td>
          </tr> }
          {lottarydata.lottary_third_win_price &&
          <tr style={{borderLeft:"5px solid #870000"}}>
            <td>
              <p>3<sup>rd</sup> Rank</p>
            </td>
            <td style={{textAlign:"center"}}>
              <p style={{ fontSize: "16px" }}> {lottarydata.lottary_third_win_users}</p>
            </td>
        
            <td style={{textAlign:"end"}}>
              <p style={{ fontSize: "16px" }}>₹ {lottarydata.lottary_third_win_price}</p>
            </td>
          </tr> }
          {lottarydata.lottary_four_win_price &&
          <tr style={{borderLeft:"5px solid #870000"}}>
            <td>
              <p>4<sup>th</sup> Rank</p>
            </td>
            <td style={{textAlign:"center"}}>
              <p style={{ fontSize: "16px" }}> {lottarydata.lottary_four_win_users}</p>
            </td>
        
            <td style={{textAlign:"end"}}>
              <p style={{ fontSize: "16px" }}>₹ {lottarydata.lottary_four_win_price}</p>
            </td>
          </tr> }
          {lottarydata.lottary_five_win_price &&
          <tr style={{borderLeft:"5px solid #870000"}}>
            <td>
              <p>5<sup>th</sup> Rank</p>
            </td>
            <td style={{textAlign:"center"}}>
              <p style={{ fontSize: "16px" }}> {lottarydata.lottary_five_win_users}</p>
            </td>
        
            <td style={{textAlign:"end"}}>
              <p style={{ fontSize: "16px" }}>₹ {lottarydata.lottary_five_win_price}</p>
            </td>
          </tr> }
          {lottarydata.lottary_six_win_price &&
          <tr style={{borderLeft:"5px solid #870000"}}>
            <td>
              <p>6<sup>th</sup> Rank</p>
            </td>
            <td style={{textAlign:"center"}}>
              <p style={{ fontSize: "16px" }}> {lottarydata.lottary_six_win_users}</p>
            </td>
        
            <td style={{textAlign:"end"}}>
              <p style={{ fontSize: "16px" }}>₹ {lottarydata.lottary_six_win_price}</p>
            </td>
          </tr> }
          {lottarydata.lottary_seven_win_price &&
          <tr style={{borderLeft:"5px solid #870000"}}>
            <td>
              <p>7<sup>th</sup> Rank</p>
            </td>
            <td style={{textAlign:"center"}}>
              <p style={{ fontSize: "16px" }}> {lottarydata.lottary_seven_win_users}</p>
            </td>
        
            <td style={{textAlign:"end"}}>
              <p style={{ fontSize: "16px" }}>₹ {lottarydata.lottary_seven_win_price}</p>
            </td>
          </tr> }
          {lottarydata.lottary_eight_win_price &&
          <tr style={{borderLeft:"5px solid #870000"}}>
            <td>
              <p>8<sup>th</sup> Rank</p>
            </td>
            <td style={{textAlign:"center"}}>
              <p style={{ fontSize: "16px" }}> {lottarydata.lottary_eight_win_users}</p>
            </td>
        
            <td style={{textAlign:"end"}}>
              <p style={{ fontSize: "16px" }}>₹ {lottarydata.lottary_eight_win_price}</p>
            </td>
          </tr> }
          {lottarydata.lottary_nine_win_price &&
          <tr style={{borderLeft:"5px solid #870000"}}>
            <td>
              <p>9<sup>th</sup> Rank</p>
            </td>
            <td style={{textAlign:"center"}}>
              <p style={{ fontSize: "16px" }}> {lottarydata.lottary_nine_win_users}</p>
            </td>
        
            <td style={{textAlign:"end"}}>
              <p style={{ fontSize: "16px" }}>₹ {lottarydata.lottary_nine_win_price}</p>
            </td>
          </tr> }
          {lottarydata.lottary_ten_win_price &&
          <tr style={{borderLeft:"5px solid #870000"}}>
            <td>
              <p>10<sup>th</sup> Rank</p>
            </td>
            <td style={{textAlign:"center"}}>
              <p style={{ fontSize: "16px" }}> {lottarydata.lottary_ten_win_users}</p>
            </td>
        
            <td style={{textAlign:"end"}}>
              <p style={{ fontSize: "16px" }}>₹ {lottarydata.lottary_ten_win_price}</p>
            </td>
          </tr> }
        </tbody>
      </Table>
    </>
  );
}
