import { Outlet, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { FiBell } from "react-icons/fi";
import { BiWallet } from "react-icons/bi";

import dp from "../img/profile.webp";
export default function TnC() {
  const [defaultMode, setDefaultMode] = useState(false);
  const location = useLocation();
  const toggleMode = () => {
    setDefaultMode(!defaultMode);
  };
  const userData = useSelector((state) => state.auth.userProfile);

  return (
    <>
      {/* <div className="nav">
        <div
          className="nav-left"
          style={{ zIndex: 100, marginLeft: 10, marginTop: 5 }}
        >
          <Link to="/home">
            <svg
              height="1.5rem"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs></defs>
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                style={{ fill: "#ffffff" }}
              ></path>
            </svg>
          </Link>
        </div>
      </div> */}
      <PerfectScrollbar>
        <header className="header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gridGap: "1rem",
            }}
          >
             <Link to="/">
            <svg
              height="1.5rem"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs></defs>
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                style={{ fill: "#ffffff" }}
              ></path>
            </svg>
          </Link>

             <h3 style={{ fontWeight: "700" }}>ROYAL LOTTARY</h3>
          </div>

          <div className="nav">
            <div>
              <Link to="/notification">
                <div>
                  <span>
                    <FiBell
                      style={{
                        width: "30px",
                        height: "25px",
                        margin: "0 10px 0 0",
                      }}
                    />
                  </span>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/wallet">
                <div>
                  <span>
                    <BiWallet
                      style={{
                        width: "30px",
                        height: "25px",
                        margin: "0 0 0 10px",
                      }}
                    />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </header>

        <div id="content" className="content_container">
          <div style={{padding:'0 1.5rem'}}>
            <span>
              <p>
                <h2>Terms & Conditions</h2>
                Effective Date: [Insert Date]
                <br />
                Please read these Terms & Conditions ("Terms") carefully before
                using the lottery app (the "App") provided by [Your Company
                Name] ("we," "us," or "our"). By accessing or using the App, you
                agree to be bound by these Terms. If you do not agree with these
                Terms, please do not use the App.
                <br />
                1. Use of the App
                <br />
                <br />
                a. Eligibility: You must be at least 18 years of age to use the
                App. You are responsible for ensuring compliance with your local
                laws and regulations regarding lottery participation.
                <br />
                b. Account Creation: You may need to create an account to access
                certain features of the App. You are responsible for maintaining
                the confidentiality of your account information and for all
                activities that occur under your account.
                <br />
                c. Prohibited Activities: You agree not to engage in any of the
                following activities while using the App:
                <br />
                - Engage in fraudulent or unlawful activities
                <br />
                - Violate these Terms or any applicable laws or regulations
                <br />
                - Interfere with the operation of the App or others' use of the
                App
                <br />
                - Distribute viruses or other malicious code
                <br />
                - Attempt to gain unauthorized access to the App or its related
                systems
                <br />
                <br />
                <br />
                2. Lottery Ticket Purchases
                <br />
                <br />
                a. Ticket Purchase: The App allows you to purchase lottery
                tickets for various lotteries. You are responsible for verifying
                the accuracy of the information provided in your ticket
                purchase.
                <br />
                b. Winnings: Any winnings from lottery tickets purchased through
                the App are subject to the terms and conditions of the specific
                lottery and relevant regulations. We are not responsible for any
                prize claims or disputes.
                <br />
                <br />
                3. Intellectual Property
                <br />
                All intellectual property, including trademarks, logos, and
                content within the App, is owned by [Your Company Name] or its
                licensors. You may not use, reproduce, or distribute any of
                these materials without our prior written consent.
                <br />
                <br />
                4. Privacy
                <br />
                Your use of the App is subject to our Privacy Policy, which
                describes how we collect, use, and protect your information. By
                using the App, you consent to our data practices as outlined in
                the Privacy Policy.
                <br />
                <br />
                5. Limitation of Liability
                <br />
                To the extent permitted by law, we disclaim all warranties and
                liability for the accuracy, availability, and functionality of
                the App. We are not liable for any direct, indirect, incidental,
                special, or consequential damages, including but not limited to
                loss of profits, data, or goodwill.
                <br />
                <br />
                6. Changes to the App and Terms
                <br />
                We may update the App or these Terms from time to time. You will
                be notified of any significant changes through the App or other
                means. Continued use of the App after such changes constitutes
                acceptance of the updated Terms.
                <br />
                <br />
                7. Termination
                <br />
                We reserve the right to terminate or suspend your access to the
                App at our discretion, without prior notice, for any violation
                of these Terms or for any other reason.
                <br />
                <br />
                8. Governing Law
                <br />
                These Terms are governed by and construed in accordance with the
                laws of [Your Jurisdiction]. Any disputes related to these Terms
                shall be resolved in the appropriate courts in [Your
                Jurisdiction].
                <br />
                <br />
                9. Contact Us
                <br />
                If you have any questions, concerns, or requests regarding these
                Terms, please contact us at [Insert Contact Information].
                <br />
                By using our App, you acknowledge that you have read,
                understood, and agreed to these Terms & Conditions. It is your
                responsibility to review these Terms periodically for any
                changes.
              </p>
            </span>
          </div>
        </div>

       
      </PerfectScrollbar>
    </>
  );
}
