import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FiBell } from "react-icons/fi";
import { BiWallet } from "react-icons/bi";
import { Outlet, useLocation } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { verifyToken } from "../store/actions/authActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { profileUpdate } from "../utils/api_services";

export default function Setting() {
  const [defaultMode, setDefaultMode] = useState(false);
  const dispatch = useDispatch();
 

  const toggleMode = () => {
    setDefaultMode(!defaultMode);
  };
  const userData = useSelector((state) => state.auth.userProfile);
  const [user, setUser] = useState({
    name:  "",
    email:  "",
  });

  const [isLoadingSignpUp, setIsLoadingSignpUp] = useState(false);
  const profileData = userData.user_data;
  // const baseUrl = window.location.origin;
  // const finalUrl = baseUrl + "/profileUpdate";

  const updateData = async (event) => {
    event.preventDefault();

    try {
      setIsLoadingSignpUp(true);

      const token = localStorage.getItem('RoyalGmAccessToken');

      const config = {
           headers: {
             Authorization: `Bearer ${token}`
           },
         };
       
         const response = await axios.post(profileUpdate,user,  config);
         const res = await response.data;
     
         

      toast.success("Profile data updated !");

      setTimeout(() => {
        dispatch(verifyToken());
      }, 2000);
    } catch (err) {
      toast.error("Profile data updation failed!");
      
    } finally {
      setIsLoadingSignpUp(false);
    }
  };

  return (
    <>
      <header className="header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gridGap: "1rem",
          }}
        >
          <Link to="/profile">
            <svg
              height="1.5rem"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs></defs>
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                style={{ fill: "#ffffff" }}
              ></path>
            </svg>
          </Link>

          <h3 style={{ fontWeight: "700" }}>ROYAL LOTTARY</h3>
        </div>

        <div className="nav">
          <div>
            <Link to="/notification">
              <div>
                <span>
                  <FiBell
                    style={{
                      width: "30px",
                      height: "25px",
                      margin: "0 10px 0 0",
                    }}
                  />
                </span>
              </div>
            </Link>
          </div>
          <div>
            <Link to="/wallet">
              <div>
                <span>
                  <BiWallet
                    style={{
                      width: "30px",
                      height: "25px",
                      margin: "0 0 0 10px",
                    }}
                  />
                </span>
              </div>
            </Link>
          </div>
        </div>
      </header>
      <div id="content" className="content_container">
        <div className="content">
          <div className="input-field " style={{ margin: "1rem" }}>
            <form onSubmit={updateData} method="POST">
              <div className="">
                <label>Name</label>
                <input
                  type="text"
                  inputMode="text"
                  defaultValue={profileData.name || ""}
                  placeholder="Enter your name"
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                  name=""
                />
              </div>
              <div className="">
                <label>Email</label>
                <input
                  type="email"
                  inputMode="text"
                  defaultValue={profileData.email || ""}
                  placeholder="Enter email address"
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  name=""
                />
              </div>
              <div className="">
                <label>Mobile No.</label>
                <input
                  type="text"
                  inputMode="numeric"
                  defaultValue={profileData.mobile || ""}
                  placeholder="Enter your mobile no."
                  readOnly={true}
                  name=""
                />
              </div>

              <div className="">
                <button className="btn" type="submit">
                  UPDATE
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
