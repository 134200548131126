import React, { useState } from "react";
import { Tab, Table, TabList, TabPanel, Tabs, tabClasses } from "@mui/joy";
import g1 from "../../img/g1.png";
export default function GameChart({ fetchWinningList, lottaryWinning }) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <div className="chart">
        <Tabs aria-label="Flex auto tabs" defaultValue={0}>
          <TabList
            tabFlex="auto"
            sx={{
              p: 0.5,
              gap: 1,
              border: "1px solid #d0d0d0",
              boxShadow: "0 1px 10px rgb(152 182 184 / 50%)",
              bgcolor: "#fff",
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                color: "#870000",
                bgcolor: "#fff",
              },
            }}
          >
            <Tab>Lottery</Tab>
            <Tab>Mini-Lottary</Tab>
          </TabList>
          <TabPanel value={0} sx={{
              p: "5px 0",}}>
            <Table  >
              <thead >
                <tr>
                <th style={{ fontSize: "14px",textAlign: "center", fontWeight: "400" }}>
                    D & T
                  </th>
                  <th style={{ fontSize: "14px",textAlign: "center", fontWeight: "400" }}>
                    Token
                  </th>
                  <th style={{ fontSize: "14px",textAlign: "center", fontWeight: "400" }}>
                    Join Fee
                  </th>
                 
                  <th style={{ fontSize: "14px",textAlign: "center", fontWeight: "400" }}>
                    Win Prize
                  </th>  
                  <th style={{ fontSize: "14px",textAlign: "center", fontWeight: "400" }}>
                    W/L
                  </th>        
                </tr>
              </thead>
              <tbody>
                {lottaryWinning &&
                  lottaryWinning.map((items, key) => (
                    <tr key={key} style={{ borderLeft: "5px solid #870000" }}>
                       <td style={{ textAlign: "end" }}>
                        <p style={{ fontSize: "12px" }}>
                          {" "}
                          {new Date(items.created_at).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                            }
                          )} <br />{" "}
                          {new Date(items.created_at).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </p>
                      </td>
                      <td style={{ textAlign: "center" ,padding:'1rem' }}>
                        <p style={{ fontSize: "12px" }}>{items.token_no}</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "12px" }}>₹ {items.amount}</p>
                      </td>
                      <td style={{ textAlign: "center" }} align="center">
                        <p style={{ fontSize: "12px" }}> <b>₹</b>{items.winning_amount ?? 0}</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "10px" }}>{items.rank !='' && items.rank != null ? (
                              <span
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  padding: "8px",
                                  borderRadius: "5px",
                                  fontSize: "10px",
                                }}
                              >
                                WIN
                              </span>
                            ) : (
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  padding: "8px",
                                  borderRadius: "5px",
                                  fontSize: "10px",
                                }}
                              >
                                LOSS
                              </span>
                            )}</p>
                      </td>
                     
                     
                    </tr>
                  ))}
              </tbody>
            </Table>
          </TabPanel>
          <TabPanel background="#f5feff" value={1}>
            <Table>
              <thead>
                <tr>
                  <th
                    style={{
                      fontSize: "12px",
                      textAlign: "left",
                      fontWeight: "400",
                    }}
                  >
                    Date/Time
                  </th>
                  <th
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "400",
                    }}
                  >
                    Amount
                  </th>

                  <th
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "400",
                    }}
                  >
                    Bet No.
                  </th>
                  <th
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "400",
                    }}
                  >
                    Win No.
                  </th>
                  <th
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "400",
                    }}
                  >
                    Win(Rs.)
                  </th>
                  <th
                    style={{
                      fontSize: "12px",
                      textAlign: "end",
                      fontWeight: "400",
                    }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p style={{ textAlign: "center" }}>Please Wait...</p>
                  </div>
                ) : (
                  <>
                    {fetchWinningList &&
                      fetchWinningList.map((row) => (
                        <tr key={row.Period}>
                          <td>
                           <p style={{ textAlign: "start", fontSize: 10 }}>
                           {" "}
                            {new Date(row.created_at).toLocaleDateString(
                              "en-GB",
                              {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                              }
                            )} <br />{" "}
                            {new Date(row.created_at).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                           </p>
                          </td>
                          <td>₹ {row.bet_amount}</td>
                          <td>{row.bet_number}</td>
                          <td>{row.win_number}</td>
                          <td>₹ {row.win_amount}</td>
                          <td style={{textAlign:'right'}}>
                            {row.status === "WIN" ? (
                              <span
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  padding: "8px",
                                  borderRadius: "5px",
                                  fontSize: "10px",
                                }}
                              >
                                WIN
                              </span>
                            ) : (
                              <span
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  padding: "8px",
                                  borderRadius: "5px",
                                  fontSize: "10px",
                                }}
                              >
                                LOSS
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </Table>{" "}
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}
