import React from "react";
import { Tab, Table, TabList, TabPanel, Tabs, tabClasses } from "@mui/joy";

export default function Chart({ deposit, withdrawal }) {
  return (
    <>
      <div className="chart">
        <Tabs aria-label="Basic tabs" defaultValue={0}>
          <TabList
            sx={{
              p: 0.5,
              gap: 1,
              justifyContent:'space-around',
              border: "1px solid #d0d0d0",
              boxShadow: "0 1px 10px rgb(152 182 184 / 50%)",
              bgcolor: "#fff",
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                color: "#870000",
                bgcolor: "#fff",
              },
            }}
          >
            <Tab>Deposite</Tab>
            <Tab>Withdrawal</Tab>
           
          </TabList>
          <TabPanel
            value={0}
            sx={{
              padding: "1rem .5rem ",
            }}
          >
            <Table>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "start",
                    }}
                    width={20}
                  >
                    #
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      paddingRight: 10,
                    }}
                  >
                    D/T
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Type
                  </th>

                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Amount ₹
                  </th>
                  <th
                    style={{
                      textAlign: "end",
                    }}
                    width={75}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {deposit &&
                  deposit.map((items, key) => (
                    <tr key={key} style={{ borderLeft: "5px solid #870000" }}>
                      <td style={{ textAlign: "start" }} width={20}>
                        <p style={{ fontSize: "12px" }}>{key + 1}</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "12px" }}>
                          {" "}
                          {new Date(items.created_at).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                            }
                          )}{" "} <br />
                          {new Date(items.created_at).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </p>
                      </td>{" "}
                      <td style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "12px" }}>{items.deposit_type === 'WIN'?<>
                        <span
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  padding: "8px",
                                  borderRadius: "5px",
                                  fontSize: "10px",
                                }}
                              >WIN</span>
                        </>:<> <span
                                style={{
                                  backgroundColor: "#29559c",
                                  color: "white",
                                  padding: "8px",
                                  borderRadius: "5px",
                                  fontSize: "10px",
                                }}
                              >DEPOSIT</span></>}</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "12px" }}>₹ {items.amount}</p>
                      </td>
                      <td style={{ textAlign: "end" }}>
                        <p style={{ fontSize: "12px" }}>
                          {" "}
                          {items.status == "ACTIVE" ? "Paid" : "Pending"}
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </TabPanel>
          <TabPanel
            background="#f5feff"
            value={1}
            sx={{
              padding: "1rem .5rem ",
            }}
          >
            <Table>
              <thead>
                <tr>
                <th
                    style={{
                      textAlign: "start",
                    }}
                    width={20}
                  >
                    #
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      paddingRight: 10,
                    }}
                  >
                    D/T
                  </th>
                  <th
                  width={"30%"}
                    style={{
                      textAlign: "center",
                      paddingRight: 10,
                    }}
                  >
                   UPI ID
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Amount ₹
                  </th>
                  <th
                    style={{
                      textAlign: "end",
                    }}
                    width={75}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {withdrawal &&
                  withdrawal.map((items, key) => (
                    <tr key={key} style={{ borderLeft: "5px solid #870000" }}>
                      <td style={{ textAlign: "start" }} width={20}>
                        <p style={{ fontSize: "13px" }}>{key + 1}</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "11px" }}>
                          {" "}
                          {new Date(items.created_at).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                            }
                          )}{" "} <br />
                          {new Date(items.created_at).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </p>
                      </td>
                      
                      <td style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "13px" }}> {items.upi_number}</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "13px" }}>₹ {items.amount}</p>
                      </td>
                      <td style={{ textAlign: "end" }}>
                        <p style={{ fontSize: "13px" }}>
                          {" "}
                    
{items.transfered === 'PAID'?<>
                        <span
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  padding: "4px",
                                  borderRadius: "5px",
                                  fontSize: "10px",
                                }}
                              >Transfered</span>
                        </>:<> <span
                                style={{
                                  backgroundColor: "#F30A0A",
                                  color: "white",
                                  padding: "4px",
                                  borderRadius: "5px",
                                  fontSize: "10px",
                                }}
                              >Pending</span></>}
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}
