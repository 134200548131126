import React , { Suspense, lazy} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/store';
import { Toaster } from 'react-hot-toast';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
     <Toaster
        position="top-center"
        reverseOrder={false}
        // toastOptions={{
        //   success: {
        //     style: {
        //       background: 'black',
        //       color:'white'
        //     },
        //   },
        //   error: {
        //     style: {
        //       background: 'red',
        //       color:'white'
        //     },
        //   },
        // }}
      />
    
  <App/>

  </Provider>
);

reportWebVitals();
