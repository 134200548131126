import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import { TabPanel } from "@mui/joy";

import Win1st from "../img/1st.svg";
import Win2nd from "../img/2nd.svg";
import Win3rd from "../img/3rd.svg";
import g1 from "../img/g1.png";
import dp from "../img/profile.webp";
import { Link, useNavigate } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import RModel from "../component/modal/Modal";
import Cashlottery from "../component/card/Cashlottery";
import TangibleLottery from "../component/card/TangibleLottery";
import Headercard from "../component/card/Headercard";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/pagination";
import { DASHBOARD_URL } from "../utils/api_services";
import axios from "axios";
import toast from "react-hot-toast";
import { setPageLoading, verifyToken } from "../store/actions/authActions";

export default function HomeScreen() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth.userProfile);
  const miniGameData = authData.mini_game_winners;

  const [completed, setCompleted] = useState(0);
  const [dashboardData,setDashboardData] = useState(null);
  const [isLoadingPage,setIsLoadingPage] = useState(false);
  useEffect(()=>{
    fetchDashboardData();
  },[]);


  const fetchDashboardData = async () =>  {
    dispatch(setPageLoading(true));
    try {
      
       const token = localStorage.getItem('RoyalGmAccessToken');

       const config = {
            headers: {
              Authorization: `Bearer ${token}`
            },
          };
        
          const response = await axios.get(DASHBOARD_URL,  config);
          const res = await response.data;
          setDashboardData(res.data);
      
    } catch (error) {
      
      toast.error('Login Expired!');
      dispatch(verifyToken());
      //fetchDashboardData();
    }finally{
       dispatch(setPageLoading(false));
    }
  }

  return (
    <> 
   
    {
      dashboardData?.myLottaries && 
      <div
        id="content"
        className="content_container"
        style={{ marginBottom: "0rem" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
       >
    
    <div>
      <p style={{ fontSize: 13, fontWeight: 700 }}>
        <span>|</span> <span style={{textTransform:'uppercase'}}>My Lotteries</span>
      </p>
    </div>

  </div>
        
        <Swiper
          pagination={{
            dynamicBullets: true,
            clickable: false,
            width: "100%",
          }}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={true}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {dashboardData?.myLottaries.map((myLottaries) => (
          <SwiperSlide key={myLottaries.id}>
            <Headercard data={myLottaries} />
          </SwiperSlide>
        ))}
        </Swiper>
      </div>
    }

{
      dashboardData?.myLottaries && 
      <Swiper
        pagination={{
          dynamicBullets: true,
          clickable: false,
          width: "100%",
        }}
        style={{ height: "8rem", width: "auto" }}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >{dashboardData?.banners.map((banners) => (
        <SwiperSlide>
          <img src={banners} alt="img1" />
        </SwiperSlide>
     ))}
      </Swiper>
}
      <RModel open={openModal} setOpen={setOpenModal} />
      <div
        id="content"
        className="content_container"
        style={{ marginTop: "0" }}
      >
        <Tabs
          aria-label="Basic tabs"
          defaultValue={0}
          sx={{
            background: "none",
          }}
        >
          <TabList
            sx={{
              p: 0.5,
              gap: 1,
              justifyContent:'space-around',
              border: "1px solid #d0d0d0",
              boxShadow: "0 1px 10px rgb(152 182 184 / 50%)",
              bgcolor: "#fff",
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                color: "#870000",
                bgcolor: "#fff",
              },
            }}
          >
            <Tab indicatorInset>
              <div className="game-icon"><b>Lottery</b></div>
            </Tab>
            <Tab indicatorInset>
              <div className="game-icon"><b>Mini-Lottery</b></div>
            </Tab>
          </TabList>
          <TabPanel
            value={0}
            sx={{
              padding: "1rem 0 0 0",
            }}
          >
            <Tabs
              variant="outlined"
              aria-label="Pricing plan"
              defaultValue={0}
              sx={{
                borderRadius: "lg",
                boxShadow: "sm",
                overflow: "auto",
              }}
            >
              <TabList
                disableUnderline
                tabFlex={1}
                sx={{
                  [`& .${tabClasses.root}`]: {
                    fontSize: "sm",
                    fontWeight: "lg",
                    bgcolor: "#fff",
                    [`&[aria-selected="true"]`]: {
                      color: "#ffffff",
                      bgcolor: "#870000",
                    },
                    [`&.${tabClasses.focusVisible}`]: {
                      outlineOffset: "-4px",
                    },
                  },
                }}
              >
                <Tab variant="soft" sx={{ flexGrow: 1 }} disableIndicator>
                  <div className="game-icon">Upcomming</div>
                </Tab>
                <Tab variant="soft" sx={{ flexGrow: 1 }} disableIndicator>
                  <div className="game-icon">Announcement </div>
                </Tab>
                <Tab variant="soft" sx={{ flexGrow: 1 }} disableIndicator>
                  <div className="game-icon">Completed</div>
                </Tab>
              </TabList>
              <TabPanel value={0}>
                <div className="lucky-draw">
                {dashboardData?.lottaries?.upcomming.length > 0 ?dashboardData?.lottaries?.upcomming.map((items) => {
                    if (items.type != "PRIZE_WINNING") {
                      return <TangibleLottery item={items} />;
                    } else {
                      return <Cashlottery item={items} />;
                    }
                  }): <><p>There are no record found!</p></>}
                </div>
              </TabPanel>
              <TabPanel value={1}>
              {dashboardData?.lottaries?.live.length > 0 ? dashboardData?.lottaries?.live.map((items) => {
                    if (items.type != "PRIZE_WINNING") {
                      return <TangibleLottery item={items} />;
                    } else {
                      return <Cashlottery item={items} />;
                    }
                  }): <><p>There are no record found!</p></>}
              </TabPanel>
              <TabPanel value={2}>
              {dashboardData?.lottaries?.completed.length > 0 ? dashboardData?.lottaries?.completed.map((items) => {
                    if (items.type != "PRIZE_WINNING") {
                      return <TangibleLottery item={items} />;
                    } else {
                      return <Cashlottery item={items} />;
                    }
                  }) : <><p>There are no record found!</p></>}
              </TabPanel>
            </Tabs>
          </TabPanel>

          <TabPanel value={1}>
            <div className="mini-game">
              <div className="content A">
                <div
                  className="c2"
                  onClick={() => {
                    return navigate("/minigame");
                  }}
                >
                  <div className="L1">
                    <h3>Spin & Win</h3>
                  </div>

                  <img src={g1} alt="" />
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
        {dashboardData?.lottaryWinnersTop3.length > 2 &&
          <div>
          <h3>
            <span>|</span> <span style={{textTransform:'uppercase'}}>{dashboardData?.WinLottary ? dashboardData?.WinLottary+' Winners' : ''}</span>
          </h3>
          <div tabIndex="3">
            <div
              className="users-list winners"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: '0 1rem',
              }}
            >
              {dashboardData?.lottaryWinnersTop3.length > 2 &&
dashboardData?.lottaryWinnersTop3.map((item) => (

      <> 
     {item.rank == 1 && (
        <>
           <div className="w-1">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                     
                      <img className="medal-1" src={Win1st} alt="" />
                      <div>
                        <img className="user-img" src={item.photo} alt="profile" />
                      </div>
                      <div className="winner-name">{item.name}</div>
                      <div className="lottery-prize">
                        <p>₹{item.winning}</p>
                      </div>
                      <div>
                        <br />
                        <br />
                      </div>
                    </div>
            </div>
        </>
      )}

      {item.rank == 2 && (
        // Render content for rank 2
        <>
          <div className="w-2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                    <img className="medal-2" src={Win2nd} alt="" />

                      <div>
                        <img className="user-img" src={item.photo} alt="profile" />
                      </div>
                      <div className="winner-name">{item.name}</div>
                      <div className="lottery-prize">
                        <p>₹{item.winning}</p>
                      </div>
                </div>
          </div>
        </>
      )}

      {item.rank == 3 && (
        // Render content for rank 3
        <>
           <div className="w-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img className="medal-3" src={Win3rd} alt="" />

                  <div>
                    <img className="user-img" src={item.photo} alt="profile" />
                  </div>
                  <div className="winner-name">{item.name}</div>
                      <div className="lottery-prize">
                        <p>₹{item.winning}</p>
                      </div>
                </div>
              </div>
        </>
      )}
      </>))}
    </div>
  </div>
</div>}
        <h3>
          <span>|</span><span style={{textTransform:'uppercase'}}>Mini-Game's Winners</span>
        </h3>
        <div tabIndex="2">
          {dashboardData?.mini_winners &&
            dashboardData?.mini_winners.map((item) => {
              return (
                <div className="users-list">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4,4fr)",
                      alignItems: "center",
                    }}
                  >
                    <div className="center">
                      <img className="user-img" src={dp} alt="profile" />
                    </div>
                    <div className="user-name">{item.name}</div>
                    <div className="game-img left">
                      <img className="user-img" src={g1} alt="profile" />
                    </div>
                    <div className="win-prize">
                      <p style={{ fontSize: 10 }}>Winning Amount</p>
                      <p style={{ margin: "0" }}>Recieved ₹{item.win_amount}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>


      
      </div>

    </>
  );
}
