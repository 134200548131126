import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import AddMoneyPop from "../component/modal/AddMoneyPop";
import { IoWallet } from "react-icons/io5";
import dp from "../img/profile.webp";
import { IoIosArrowForward } from "react-icons/io";
import { GrSupport, GrLock, GrDocumentText, GrLogout, GrSettingsOption, GrUserAdd, GrUserExpert } from "react-icons/gr";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { FaPersonRifle } from "react-icons/fa6";
export default function ProfileScreen() {
  const [openModal, setOpenModal] = React.useState(false);
  const authData = useSelector((state) => state.auth.userProfile.user_data);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const [defaultMode, setDefaultMode] = useState(false);
  const location = useLocation();
  const toggleMode = () => {
    setDefaultMode(!defaultMode);
  };
  const userData = useSelector((state) => state.auth.userProfile);


  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");

    if (confirmLogout) {
      // Clear local storage
      localStorage.clear();
      // Redirect to the home page
      window.location.href = "/"; // You can use React Router for more control
    }
  };

  return (
    <>
      <div className="profile-drawer">
        <div style={{ position: "relative", zIndex: 100, top: 0 }}>
          <div className="profile">
            <div>
              <div className="profile-img" style={{
                width:"50px"
              }}>
                <img  className="user-img" src={dp} alt="profile" />
              </div>
            </div>
            <div className="profile-info">
              <ul>
                <div
                  className="user-name"
                  style={{ textTransform: "uppercase" }}
                >
                  <h4>{authData.name}</h4>
                </div>
                <div className="UID">
                  <p>CUSTOMER ID | RL{authData.mobile}</p>
                </div>
                <div style={{fontSize:14}}>
                  <p>Mobile No.: {authData.mobile}</p>
                </div>
                <div style={{fontSize:14}}>
                  <p>Email : {authData.email}</p>
                </div>
              <div className="">
             
              </div>

              </ul>
              
            </div>
          </div>
        </div>
        <div
          id="content"
          className="content_container"
          style={{ margin: "0rem 0 5rem 0" }}
        >
          <div className="profileBalance">
            <div className="wallet-wrapper" style={{
                  display: "flex",
                  alignItems: "center",
                  direction: "row",
                  justifyContent: "space-between",
                }}>
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "18px",
                  fontWeight: "bolder",
                  justifyContent: "left",
                }}
              >
                <IoWallet style={{ color: "#ffffff", width: "20px",marginRight:'10px' }} />
                <p>Total Wallet Balance</p>
              </div>
              <div>
                <p style={{ fontSize: 20 }}>₹{authData.wallet_balance}</p>
              </div>
            </div>
          </div>

          <div tabIndex="2">

            <div className="profile-List">
              <Link to="/support" style={{textDecoration: "none",}} >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      width: "100%",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      <GrSupport style={{ width: "1.5em", height: "2em", color:"#727272" }} />
                    </span>
                    <div className="user-name"><span>Support</span></div>
                  </div>

                  <div>
                    <IoIosArrowForward />
                  </div>
                </div>
              </Link>
            </div>
            <div className="profile-List">
            <Link to="/terms&conditions" style={{textDecoration: "none",}} >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <GrDocumentText style={{ width: "1.5em", height: "2em", color:"#727272" }} />
                  </span>
                  <div className="user-name"><span>Terms & Conditions</span></div>
                </div>

                <div>
                  <IoIosArrowForward />
                </div>
              </div>
              </Link>
            </div>
            <div className="profile-List">
            <Link to="/privacypolicy" style={{textDecoration: "none",}} >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  
                }}
              >
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <GrLock style={{ width: "1.5em", height: "2em", color:"#727272"}} />
                  </span>
                  <div className="user-name"><span>Privacy Policy</span></div>
                </div>

                <div>
                  <IoIosArrowForward />
                </div>
              </div>
              </Link>
            </div>
            <div className="profile-List">
            <Link to="/refer-and-earn" style={{textDecoration: "none",}} >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  
                }}
              >
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <GrUserExpert style={{ width: "1.5em", height: "2em" , color:"#727272"}} />
                  </span>
                  <div className="user-name"><span> Refer & Earn</span><span className="referearn">NEW</span></div>
                </div>

                <div>
                  <IoIosArrowForward />
                </div>
              </div>
              </Link>
            </div>
            <div className="profile-List">
            <Link to="/setting" style={{textDecoration: "none",}} >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  
                }}
              >
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <GrSettingsOption style={{ width: "1.5em", height: "2em", color:"#727272" }} />
                  </span>
                  <div className="user-name"><span>My Info & Settings</span></div>
                </div>

                <div>
                  <IoIosArrowForward />
                </div>
              </div>
              </Link>
            </div>
            <div className="profile-List">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={handleLogout}
              >
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <GrLogout style={{ width: "1.5em", height: "2em", color:"#727272" }} />
                  </span>
                  <div className="user-name"><span>Logout</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>
    </>
  );
}
