import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiBell, FiFacebook } from "react-icons/fi";
import { BiWallet } from "react-icons/bi";
import { FaCopy, FaTwitter, FaWhatsapp } from "react-icons/fa6";


export default function Notification() {
  return (
    <>
      <header className="header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gridGap: "1rem",
          }}
        >
          <Link to="/">
            <svg
              height="1.5rem"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs></defs>
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                style={{ fill: "#ffffff" }}
              ></path>
            </svg>
          </Link>

           <h3 style={{ fontWeight: "700" }}>ROYAL LOTTARY</h3>
        </div>
        <div className="nav">
          <div>
            <Link to="/">
              <div>
                <span>
                  <FiBell
                    style={{
                      width: "30px",
                      height: "25px",
                      margin: "0 10px 0 0",
                    }}
                  />
                </span>
              </div>
            </Link>
          </div>
          <div>
            <Link to="/wallet">
              <div>
                <span>
                  <BiWallet
                    style={{
                      width: "30px",
                      height: "25px",
                      margin: "0 0 0 10px",
                    }}
                  />
                </span>
              </div>
            </Link>
          </div>
        </div>
      </header>

      <div
        id="content"
        className="content_container"
        style={{ margin: "3rem 0" }}
      >
        <div className="content">

            <h4>NO data Available</h4>
        </div>

      </div>
    </>
  );
}
