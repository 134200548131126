export const ROOT_URL = 'https://apis.royallottary.com';
export const BASE_URL = ROOT_URL+'/api';
// export const ROOT_URL = 'http://localhost/office-projects/royallottary';
// export const BASE_URL = ROOT_URL+'/api';
export const USER_LOGIN_URL = BASE_URL+'/login';
export const USER_REGISTER_URL = BASE_URL+'/register';
export const VERIFY_USER_REGISTER_URL = BASE_URL+'/signup-verify-user';
export const VERIFY_USER_LOGIN_URL = BASE_URL+'/login-verify-user';
export const DASHBOARD_URL = BASE_URL+'/dashboard';
export const USER_BEFORE_VERIFY_REGISTER = BASE_URL+'/new-user-check';
export const RESEND_VERIFICATION_MAIL = BASE_URL+'/resent-verification-mail';
export const VERIFY_ACCESS_TOKEN = BASE_URL+'/all-data';
export const SINGLE_LOTTARY_DATA = BASE_URL+'/single-lottary-data/';
export const RAZORPAY_GENERATE_ORDER_ID = BASE_URL+'/razorpay-order-generate';
export const RAZORPAY_RESPONSE_ORDER_ID = BASE_URL+'/razorpay-order-response';
export const WALLET_GENERATE_ORDER_ID = BASE_URL+'/razorpay-wallet-order-generate';
export const WALLET_RESPONSE_PAYMENT_ID = BASE_URL+'/razorpay-wallet-order-response';
export const WALLET_FETCH_LIST = BASE_URL+'/wallet-list';
export const SINGLE_GAME_LIST = BASE_URL+'/single-game-list';
export const SINGLE_GAME_APPLY = BASE_URL+'/single-game-apply';
export const REQUEST_WALLET_WITHDRAWAL_AMOUNT = BASE_URL+'/withdrawal-request';
export const MY_GAMES_LIST =    BASE_URL+'/my-game-list';
export const WINNERS = BASE_URL+'/winners';
export const profileUpdate = BASE_URL+'/profileUpdate';