import React, { useState, useEffect } from "react";
import { TfiCup } from "react-icons/tfi";
import { FaAward } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Headercard(props) {
  const data = props.data;
    const navigate = useNavigate();
  //   const item = props.item;
  //   const isShow = props.show ?? false;
  //   const [completed, setCompleted] = useState(0);

  //   const [isSlotfull, setIsSlotfull] = useState(false);
  //   useEffect(()=>{
  //     var total = item.target_user;
  //     var joined = item.joined_users;
  //     var per = (total-joined);
  //     var p = 100-(per/total)*100;
  //     var total = item.target_user || 0;
  //     var joined = item.joined_users || 0;
  //   if( joined == total)
  //   {
  //     setCompleted(100);
  //     setIsSlotfull(true);
  //   }else{
  //     setCompleted(p);
  //     setIsSlotfull(false);
  //   }

  //   },[]);

  return (
    <>
      <div className="content">
        <p
          style={{
            padding: ".3rem 1rem",
            borderBottom: "1px solid #d0d0d0",
            background: "#870000",
            borderRadius: "10px 10px 0 0 ",
            color: "#fff",
          }}
        >
          Prize Pool
        </p>
        <div className="money-lottery">
          <div className="L2">
            <p style={{ fontSize: 18, fontWeight: 700 }}>
              {data.name} <br />
              <span>₹ { data.pricePool} </span>
            
            </p>
          </div>

          <div style={{ display: "flex" }}>
            <p></p>
            {/*  {isShow === false ? 
                      <button
                        onClick={() => {
                          return navigate(`/lottery?query=${item.id}`);
                        }}
                        className="btn"
                      >
                        Join ₹ {item.fees}
                      </button> : <></> } */}
            <button
              onClick={() => {
                return navigate(`/lottery?query=${data.id}`);
              }}
              className="btn"
            >
              Show 
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            background: "#ddd",
            borderRadius: 3,
          }}
        >
          <div>
            <h6>
              <FaAward /> {data.firstTag}
            </h6>
          </div>
          <div>
            <h6>
              <TfiCup /> {data.distributeTag}%
            </h6>
          </div>
          <div>
            <h6>Up to {data.minimumTag}</h6>
          </div>
        </div>
      </div>
    </>
  );
}
