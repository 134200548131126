import {
  HashRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import "./styles/app.css";
import "./styles/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import LoginScreen from "./pages/LoginScreen";
import SignupScreen from "./pages/SignupScreen";
import HomeScreen from "./pages/HomeScreen";
import Wallet from "./pages/Wallet";
import MyGames from "./pages/MyGames";
import SupportScreen from "./pages/SupportScreen";
import ProfileScreen from "./pages/ProfileScreen";
import Minigame from "./component/game/Minigame";
import Lottery from "./pages/Lottery";
import Privacy from "./pages/Privacy";
import TnC from "./pages/TnC";
import logo from "./img/ROYAL LOTTARY LOGO.gif";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "./store/actions/authActions";
import { Suspense, useEffect } from "react";
import MainLayout from "./pages/layout/MainLayout";
import Winner from "./pages/Winner";
import Setting from "./pages/Settings";
import LoadingOverlay from "react-loading-overlay";
import PulseLoader from "react-spinners/PulseLoader";
import Referandearn from "./pages/Referandearn";
import Notification from "./pages/Notification";
import { useState } from "react";
import LoginOtpVerify from "./pages/login_otp_verify";
const PrivateRoute = ({ path, isAuthenticated, ...rest }) => {
  return isAuthenticated ? (
    <Routes>
      <Route {...rest} path={path} />
    </Routes>
  ) : (
    <Navigate to="/" />
  );
};

export default function App() {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const handleContextMenu = (e) => {
  //     e.preventDefault(); // Prevent the context menu from appearing
  //   };

  //   window.addEventListener('contextmenu', handleContextMenu);

  //   return () => {
  //     window.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);
  useEffect(() => {
    dispatch(verifyToken());
  }, []);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  let isLoading = useSelector((state) => state.auth.isLoading); // Get loading status
  const pageIsLoading = useSelector((state) => state.auth.isPageLoading);
  useEffect(() => {
    if (isLoading) {
     
        setShowLoadingScreen(true);
     
        setTimeout(() => {
          setShowLoadingScreen(false);
        },[2000]);
    }
  }, []);

  if (showLoadingScreen) {
   
    return (
      <>
        {" "}
        <div className="splash-screen">
          <div className="splash-title">
            <img src={logo} alt="" />
            <h3
              style={{
                fontfamily: "Roboto",
                fontWeight: "700",
                color: "white",
              }}
            >
              ROYAL LOTTARY
            </h3>
          </div>
        </div>
        {/* <div className="pos-center">
      <Ringloder />
    </div> */}
      </>
    );
  }
  const companyDashboardRoute = "/home";
  return (
    <>
      <LoadingOverlay
        active={pageIsLoading}
        spinner={<PulseLoader color="#ffffff" />}
      >
        <div className="app_container">
          <Router basename="">
            <Routes>
              <Route
                path="/"
                exact
                element={
                  isAuthenticated ? <Navigate to={"/home"} /> : <LoginScreen />
                }
              />
              <Route path="/signup" element={<SignupScreen />} />
              <Route path="/login-account-verification" element={<LoginOtpVerify/>} />
              <Route element={<MainLayout />}>
                <Route
                  path={companyDashboardRoute}
                  element={
                    <PrivateRoute
                      path="/"
                      isAuthenticated={isAuthenticated}
                      element={<HomeScreen />}
                    />
                  }
                />
                <Route
                  path={"/profile"}
                  element={
                    <PrivateRoute
                      path="/"
                      isAuthenticated={isAuthenticated}
                      element={<ProfileScreen />}
                    />
                  }
                />
                <Route
                  path={"/winner"}
                  element={
                    <PrivateRoute
                      path="/"
                      isAuthenticated={isAuthenticated}
                      element={<Winner />}
                    />
                  }
                />
                <Route
                  path={"/wallet"}
                  element={
                    <PrivateRoute
                      path="/"
                      isAuthenticated={isAuthenticated}
                      element={<Wallet />}
                    />
                  }
                />
                <Route
                  path={"/mygames"}
                  element={
                    <PrivateRoute
                      path="/"
                      isAuthenticated={isAuthenticated}
                      element={<MyGames />}
                    />
                  }
                />
                <Route
                  path={"/support"}
                  element={
                    <PrivateRoute
                      path="/"
                      isAuthenticated={isAuthenticated}
                      element={<SupportScreen />}
                    />
                  }
                />
              </Route>

              {/* outside of main layout */}
              <Route
                path={"/setting"}
                element={
                  <PrivateRoute
                    path="/"
                    isAuthenticated={isAuthenticated}
                    element={<Setting />}
                  />
                }
              />
              <Route
                path={"/notification"}
                element={
                  <PrivateRoute
                    path="/"
                    isAuthenticated={isAuthenticated}
                    element={<Notification />}
                  />
                }
              />
              <Route
                path={"/refer-and-earn"}
                element={
                  <PrivateRoute
                    path="/"
                    isAuthenticated={isAuthenticated}
                    element={<Referandearn />}
                  />
                }
              />

              <Route
                path={"/lottery"}
                element={
                  <PrivateRoute
                    path="/"
                    isAuthenticated={isAuthenticated}
                    element={<Lottery />}
                  />
                }
              />
              <Route
                path={"/privacypolicy"}
                element={
                  <PrivateRoute
                    path="/"
                    isAuthenticated={isAuthenticated}
                    element={<Privacy />}
                  />
                }
              />
              <Route
                path={"/terms&conditions"}
                element={
                  <PrivateRoute
                    path="/"
                    isAuthenticated={isAuthenticated}
                    element={<TnC />}
                  />
                }
              />

              {/* <Route path={'/profile'} 
                      element={
                        <PrivateRoute path="/" isAuthenticated={isAuthenticated} element={<ProfileScreen />}/>
                      }
                    /> */}
              <Route
                path={"/minigame"}
                element={
                  <PrivateRoute
                    path="/"
                    isAuthenticated={isAuthenticated}
                    element={<Minigame />}
                  />
                }
              />

              {/* <Route path='/home' exact element={<HomeScreen/>} />
                <Route path='/wallet' exact element={<Wallet/>}/>
                <Route path='/mygames' exact element={<MyGames/>}/>
                <Route path='/support' exact element={<SupportScreen/>}/>
                <Route path='/profile' exact element={<ProfileScreen/>}/> 
                <Route path='/lottery' exact element={<Lottery/>}/>
                <Route path='/redgreengame' exact element={<RedGreen/>}/>   
                <Route path='/spinnersheel' exact element={<SpinnerWheel/>}/>   
                <Route path='/authorization' exact element={<Authorization/>}/>    */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Router>
        </div>
      </LoadingOverlay>
    </>
  );
}

function PageNotFound() {
  return (
    <>
      <Link to="/signup">Signup</Link>
    </>
  );
}
