import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiBell, FiFacebook } from "react-icons/fi";
import { BiWallet } from "react-icons/bi";
import { FaCopy, FaTwitter, FaWhatsapp } from "react-icons/fa6";
import Referal from "./../img/referal.svg";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
export default function Referandearn() {
  const authData = useSelector((state) => state.auth.userProfile);
  const userdata = authData.user_data;
  const location = useLocation();
  const baseUrl = window.location.origin;
  const copyReferelUrl = () => {
    const finalUrl =
      baseUrl + "/#/signup?referel-code=" + userdata.my_referrel_code;
    navigator.clipboard.writeText(finalUrl).then(() => {
      toast.success("Copied Successfully");
    });
  };

  return (
    <>
      <header className="header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gridGap: "1rem",
          }}
        >
          <Link to="/profile">
            <svg
              height="1.5rem"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs></defs>
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                style={{ fill: "#ffffff" }}
              ></path>
            </svg>
          </Link>

          <h3 style={{ fontWeight: "700" }}>ROYAL LOTTARY</h3>
        </div>
        <div className="nav">
          <div>
            <Link to="/">
              <div>
                <span>
                  <FiBell
                    style={{
                      width: "30px",
                      height: "25px",
                      margin: "0 10px 0 0",
                    }}
                  />
                </span>
              </div>
            </Link>
          </div>
          <div>
            <Link to="/wallet">
              <div>
                <span>
                  <BiWallet
                    style={{
                      width: "30px",
                      height: "25px",
                      margin: "0 0 0 10px",
                    }}
                  />
                </span>
              </div>
            </Link>
          </div>
        </div>
      </header>

      <div
        id="content"
        className="content_container"
        style={{ margin: "3rem 0" }}
      >
        <div className="refferal-box">
          <img src={Referal} alt="" height={200} />

          <div className="invite-friends ">
            <h3>Refer to your friends and Get a cash rewards ₹50 </h3>
            <p>
              Share this code with your friends and after they install both of
              you will get ₹50 cash reward.
            </p>
            <br />
            <div className="invite">
              <button type="button" onClick={copyReferelUrl}>
                {userdata.my_referrel_code}
              </button>{" "}
              <FaCopy />
            </div>
          </div>
          <p className="invite-link">Or share this link:</p>
          <div className="share-icons ">
            <div className="share-icon">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  "https://royallottary.com/"
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiFacebook />
              </a>
            </div>
            <div className="share-icon">
              <a
                href={`https://api.whatsapp.com/send?text=Check%20out%20this%20referral%20code%3A%20${userdata.my_referral_code}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp />
              </a>
            </div>
            <div className="share-icon">
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  "your-website-url"
                )}&text=Check%20out%20this%20referral%20code%3A%20${
                  userdata.my_referral_code
                }&via=your-twitter-handle`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
