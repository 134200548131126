import axios from "axios";
import { VERIFY_ACCESS_TOKEN } from "../../utils/api_services";
import { toast } from "react-hot-toast";
import { LOGIN_SUCCESS, LOGOUT, SET_LOADING, SET_USER_PROFILE , PAGE_IS_LOADING } from "../constants/authConstants";

export const loginSuccess = (token) => ({
    type: LOGIN_SUCCESS,
    payload: token,
});

export const setUserProfile = (profile) => ({
    type: SET_USER_PROFILE,
    payload: profile,
  });
  export const setLoading = (isLoading) => ({
    type: SET_LOADING,
    payload: isLoading,
  });
  export const setPageLoading = (isPageLoading) => ({
    type: PAGE_IS_LOADING,
    payload: isPageLoading,
  });

  export const logout = () => ({
    type: LOGOUT,
  });


export const verifyToken = () => async (dispatch) => {
    try {
      dispatch(setLoading(true)); // Set loading to true
      const token = localStorage.getItem('RoyalGmAccessToken');
    
      if (token) {
       
          const config = {
            headers: {
              Authorization: `Bearer ${token}`
            },
          };
        
          const response = await axios.get(VERIFY_ACCESS_TOKEN,  config);
      
          if (response.data.status) {
            dispatch(loginSuccess(token));
            dispatch(setUserProfile(response.data.data)); // Dispatch user profile
          } else {
            ///localStorage.removeItem('accessToken');
          }
      }
    } catch (error) {
      
    }finally{
      dispatch(setLoading(false)); // Set loading to false regardless of the outcome
    }
  };