import React, { useState, useEffect } from "react";
import ProgressBar from "../functions/Progressbar";
import { Link, useNavigate } from "react-router-dom";
import { TfiCup } from "react-icons/tfi";
import { FaAward } from "react-icons/fa6";
import { useSelector } from "react-redux";

export default function Cashlottery(props) {
  const navigate = useNavigate();
  const item = props.item;
  const isShow = props.show ?? false;
  const [completed, setCompleted] = useState(0);

  const [isSlotfull, setIsSlotfull] = useState(false);
  useEffect(() => {
    var total = item.target_user;
    var joined = item.joined_users;
    var per = total - joined;
    var p = 100 - (per / total) * 100;
    var total = item.target_user || 0;
    var joined = item.joined_users || 0;
    if (joined == total) {
      setCompleted(100);
      setIsSlotfull(true);
    } else {
      setCompleted(p);
      setIsSlotfull(false);
    }
  }, []);

  return (
    <>
      <div className="content" key={item.id}>
        <p
          style={{
            padding: ".3rem 1rem",
            borderBottom: "1px solid #d0d0d0",
            background: "#870000",
            borderRadius: "10px 10px 0 0 ",
            color: "#fff",
          }}
        >
          Prize Pool
        </p>
        <div className="money-lottery">
          <div className="L2">
            <p style={{ fontSize: 15 ,fontWeight: 700 }}>
              {item.name} <br /> <span>₹ {item.pricePool}{item.lottary_amount} </span>
            </p>
          </div>

          {isShow === false ? (
            <button
              onClick={() => {
                return navigate(`/lottery?query=${item.id}`);
              }}
              className="btn"
            >
              {item.status == "PENDING" ? "Join ₹" + item.fees : "Show"}
            </button>
          ) : (
            <></>
          )}
        </div>

        <div className="L2">
          <ProgressBar completed={completed} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span>
              {isSlotfull ? (
                <div>
                  <h6>All Spots are filled</h6>
                </div>
              ) : (
                <div>
                  <h6>{item.target_user - item.joined_users} Spot left</h6>
                </div>
              )}
            </span>
            <h6>{item.target_user} Spots</h6>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            background: "#ddd",
            borderRadius: 3,
          }}
        >
          <div>
            <h6>
              <FaAward /> {item.firstTag}
            </h6>
          </div>
          <div>
            <h6>
              <TfiCup /> {item.distributeTag} %
            </h6>
          </div>
          <div>
            <h6>Up to {item.minimumTag} </h6>
          </div>
        </div>
      </div>
    </>
  );
}
