import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Gamecahrt from "../component/list/GameChart";
import { MY_GAMES_LIST } from "../utils/api_services";
import axios from "axios";
export default function MyGames() {

  const [miniGameWinnings,setMiniGameWinnings] = useState(null);
  const [lottaryWinning,setLottaryWinning] = useState(null);

  useEffect(()=>{
    
    fetchWallet();
  },[]);
  const fetchWallet = async () =>  {
    try {
      
      const token = localStorage.getItem('RoyalGmAccessToken');

       const config = {
            headers: {
              Authorization: `Bearer ${token}`
            },
          };
        
          const response = await axios.get(MY_GAMES_LIST,  config);
          const res = await response.data;
          setMiniGameWinnings(res.data.winnerList);
          setLottaryWinning(res.data.mytokens);
      
    } catch (error) {
      
      console.error('Error verifying token:', error);
    }finally{
      
    }
  }
  return (
    <>
   
        {/* <div className="backnav">
          <Link to="/home">
            <svg
              height="1.5rem"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs></defs>
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                style={{ fill: "#ffffff" }}
              ></path>
            </svg>
          </Link>
        </div> */}
        <div id="content" className="content_container">
          <h4>
            <span>|</span><span style={{textTransform:'uppercase'}}>My Result</span>
          </h4>

          <div tabIndex="2">
            <Gamecahrt fetchWinningList={miniGameWinnings} lottaryWinning={lottaryWinning}  />
          </div>
        </div>
       
    </>
  );
}
