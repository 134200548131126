import React,{useState} from 'react'
import toast from 'react-hot-toast';

function LoginOtpVerify(props) {

    const [userId, setUserId] = useState(null);
    const [otpValue,setOtpValue] = useState(null);
    const [mobileNum, setMobileNum] = useState("");
    const [verificationStatus, setVerificationStatus] = useState("");
    const [isOtpSend, setIsOtpSend] = useState(false);
    const [isLoadingSignpUp,setIsLoadingSignup] = useState(false);
    const sendOtp =()=>{

    }
    const verifyOtp = ()=>{

    }

    const onSignUpClick = ()=>{

    }
  return (
    <>
      <header className="header" style={{ zIndex: 0 }}>
      <h3 style={{ fontfamily: "Roboto",fontWeight: "700" }}>ROYAL LOTTARY</h3>
      </header>

      <div className="login_container">
        <div className="login__container-wrapper">
          <div className="login__container-heading" style={{ margin: "1 0" }}>
            <h2>Account Verification here</h2>
          </div>
          <form onSubmit={onSignUpClick} method="post">
          <div className="signup_container-form">
            <div className="step1">
              <div className="signup_input-field">
                <input
                  type="text"
                  maxLength="50"
                  name=""
                  id="nameInput"
                  placeholder="Enter otp"
                  required={true}
                  value={otpValue}
                  onChange={(e) => setOtpValue( e.target.value )}
                />
</div>

              {isLoadingSignpUp ? 
              <button className="btn" id="loginBtn" type="button" disabled="">

              <span className="spinner-grow" role="status" aria-hidden="true"></span>
              &nbsp;
              Please Wait ...
            </button> : 
                        <button className="btn" id="loginBtn" type="submit">Sign Up</button>}
            </div>
          </div>
</form>
         
        </div>
      </div>
    </>

  )
}

export default LoginOtpVerify